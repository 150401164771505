.table-container {
  width: 100%;
}

.table-head {
  height: auto;
  margin-bottom: 20px;
  border-radius: 0;
  background-color: transparent;
  display: flex;
  border-top: 0px solid #e4ebed;
  border-bottom: 0px solid #e4ebed;

  .table-row {
    @include max-large-devices {
      display: none;
    }
  }
}

.table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 18px 0 18px;
}

.th {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: $white;

  &:last-child {
    justify-content: center;
  }

  .sort-btn-box {
    display: inline-block;
    margin-left: 8px;
    // .icon-up-down-arrow {
    //   display: inline-block;
    //   cursor: pointer;
    //   height: 10px;
    //   width: 8px;
    //   background: url("list-sort.svg") no-repeat center;
    // }
  }
}

.table-body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100%;

  .table-row {
    height: 51px;
    min-height: 51px;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid #e4ebed;
    padding: 20px 18px 14px 18px;
    margin-bottom: 2px;

    &.no-record {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 18%;
    }

    @include max-large-devices {
      width: 50%;
      flex-wrap: wrap;
      height: 100%;
      min-height: 100%;
      padding: 24px 24px 12px;
      position: relative;
    }

    &.inactive {
      .td {
        opacity: 1;
        color: $white;

        &:last-child {
          opacity: 1;
          pointer-events: initial;
          border-bottom: 0;
        }
      }
    }

    &.featured {
      background-color: rgba(#feb700, 0.15);

      .badge-warning {
        display: block;
      }
    }
  }
}

.td {
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 0;
  font-size: 14px;
  font-weight: 500;
  color: $text-grey;
  line-height: 1.3;
  word-break: break-word;

  @include max-large-devices {
    max-width: 50% !important;
    flex: 0 0 50% !important;
    flex-wrap: wrap;
    height: 100%;
    margin-bottom: 25px;

    &::before {
      content: attr(data-label);
      float: left;
      font-weight: 500;
      text-transform: capitalize;
      width: 100%;
      margin-bottom: 5px;
    }

  }

  .td-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:last-child {
    justify-content: space-between;
  }

  .badge {
    position: absolute;
    top: 0;
    left: 40px;
  }
}

.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px;

  .count-text {
    font-size: 12px;
    color: red;
  }
}

.contest-table-power {
  .td {
    .tooltip-container {
      width: 100%;

      .tooltip-text {
        max-width: 200px;
        top: 100%;
      }
    }

    .td-text {
      display: inline-block;
      max-width: 100%;
    }
  }
}

.contest-table {
  .tooltip-container {
    .tooltip-text {
      min-width: 150px;
    }
  }

  .table-row {

    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 14%;
        max-width: 14%;

        @include computer-max {
          max-width: 12%;
          flex: 0 0 12%;
        }

        @include max-large-devices {
          display: none;
        }
      }

      &:nth-child(2) {
        flex: 0 0 9%;
        max-width: 9%;

        @include computer-max {
          max-width: 8%;
          flex: 0 0 8%;

        }
      }

      &:nth-child(3) {
        flex: 0 0 9%;
        max-width: 9%;

        @include computer-max {
          max-width: 8%;
          flex: 0 0 8%;

        }
      }

      &:nth-child(4) {
        flex: 0 0 8%;
        max-width: 8%;

        @include computer-max {
          max-width: 8%;
          flex: 0 0 8%;

        }
      }

      &:nth-child(5) {
        flex: 0 0 9%;
        max-width: 9%;

        @include computer-max {
          max-width: 10%;
          flex: 0 0 10%;
        }
      }

      &:nth-child(6) {
        flex: 0 0 8%;
        max-width: 8%;

        @include computer-max {
          max-width: 8%;
          flex: 0 0 8%;

        }
      }

      &:nth-child(7) {
        flex: 0 0 6%;
        max-width: 6%;

        @include computer-max {
          max-width: 5%;
          flex: 0 0 5%;

        }
      }

      &:nth-child(8) {
        flex: 0 0 6%;
        max-width: 6%;

        @include computer-max {
          max-width: 5%;
          flex: 0 0 5%;

        }
      }

      &:nth-child(9) {
        flex: 0 0 10%;
        max-width: 10%;

        @include computer-max {
          max-width: 8%;
          flex: 0 0 8%;

        }
      }

      &:nth-child(10) {
        flex: 0 0 10%;
        max-width: 10%;

        @include computer-max {
          max-width: 8%;
          flex: 0 0 8%;

        }

        .icon-next-arrow-grey {
          padding-left: 10px;
          padding-bottom: 8px;
        }
      }

      &:nth-child(11) {
        flex: 0 0 11%;
        max-width: 11%;
        justify-content: space-between;

        @include computer-max {
          max-width: 12%;
          flex: 0 0 12%;
        }

        .icon-next-arrow-grey {
          cursor: pointer;

          &::before {
            color: $text-grey;
            font-size: 10px;
          }

          @include max-large-devices {
            position: absolute;
            bottom: 16px;
            right: 16px;
          }
        }
      }
    }
  }

  &.contest-table-geo-thermal {

    .table-row {
      justify-content: flex-start;

      .th,
      .td {
        .td-text {
          white-space: break-spaces;
        }

        @include computer-max {
          font-size: 12px;
        }

        &:nth-child(2) {
          flex: 0 0 6%;
          max-width: 6%;

          @include computer-max {
            max-width: 6%;
            flex: 0 0 6%;
          }
        }

        &:nth-child(3) {
          flex: 0 0 13%;
          max-width: 13%;

          @include computer-max {
            max-width: 13%;
            flex: 0 0 13%;
          }
        }

        &:nth-child(4) {
          flex: 0 0 9%;
          max-width: 9%;

          @include computer-max {
            max-width: 9%;
            flex: 0 0 9%;
          }
        }

        &:nth-child(6) {
          flex: 0 0 6%;
          max-width: 6%;

          @include computer-max {
            max-width: 6%;
            flex: 0 0 6%;

          }
        }

        &:nth-child(9) {
          flex: 0 0 6%;
          max-width: 6%;

          @include computer-max {
            max-width: 6%;
            flex: 0 0 6%;

          }
        }

        &:nth-child(10) {
          flex: 0 0 6%;
          max-width: 6%;

          @include computer-max {
            max-width: 6%;
            flex: 0 0 6%;

          }
        }

        &:nth-child(11) {
          flex: 0 0 6%;
          max-width: 6%;
          justify-content: space-between;

          @include computer-max {
            max-width: 6%;
            flex: 0 0 6%;
          }
        }

        &:nth-child(12) {
          flex: 0 0 6%;
          max-width: 6%;
          justify-content: space-between;

          @include computer-max {
            max-width: 6%;
            flex: 0 0 6%;
          }
        }

        &:nth-child(13) {
          flex: 0 0 10%;
          max-width: 10%;
          justify-content: flex-start;
          padding-right: 20px;
          .tooltip-container{
            max-width: 110px;
          }

          @include computer-max {
            max-width: 10%;
            flex: 0 0 10%;
          }
        }
      }
    }
  }

  &.admin-table-container {
    .table-head {
      margin-bottom: 5px;

      .th {
        text-transform: uppercase;
      }
    }

    .table-body {
      .table-row {
        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }

      }
    }

    .table-row {
      margin-bottom: 0;
      border-bottom: 0;
      background-color: transparent;
      backdrop-filter: none;
      padding: 12.5px 45px;

      @include computer-max-1440 {
        padding: 12.5px 30px;
      }

      &.inactive {
        &:hover {
          background-color: transparent;
        }
      }

      .th,
      .td {
        color: $theme-black;

        &:nth-child(1) {
          flex: 0 0 22%;
          max-width: 22%;

          .tab-left-part {
            cursor: pointer;
            width: 100%;

            .right-sec {
              width: 100%;
            }
          }
        }

        &:nth-child(2) {
          flex: 0 0 19%;
          max-width: 19%;

          .tooltip-container {
            .tooltip-text {
              max-width: 350px;
            }
          }

          .td-text {
            max-width: 210px;

            @include computer-max-1440 {
              max-width: 150px;
            }
          }
        }

        &:nth-child(3) {
          flex: 0 0 18%;
          max-width: 18%;
        }

        &:nth-child(4) {
          flex: 0 0 9%;
          max-width: 9%;
          text-transform: capitalize;
        }

        &:nth-child(5) {
          flex: 0 0 9%;
          max-width: 9%;
        }

        &:nth-child(6) {
          flex: 0 0 8%;
          max-width: 8%;
          text-transform: capitalize;
        }

        &:nth-child(7) {
          flex: 0 0 9%;
          max-width: 9%;
          text-transform: capitalize;
        }

        &:nth-child(8) {
          flex: 0 0 6%;
          max-width: 6%;
          display: flex;
          justify-content: flex-end;

          .three-dot-dropdown {
            .dropdown-menu {
              width: 186px;
            }
          }
        }

        .status {
          &.active {
            color: $theme-success;
          }

          &.inactive {
            color: $text-error;
          }

          &.pending {
            color: $text-danger;
          }
        }

        .company-name {
          @include computer-max-1440 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 130px;
          }
        }

        @include computer-max-1440 {
          &:nth-child(1) {
            flex: 0 0 22%;
            max-width: 22%;

            .tab-left-part {
              cursor: pointer;
            }
          }

          &:nth-child(2) {
            flex: 0 0 17%;
            max-width: 17%;

            .td-text {
              max-width: 140px;
            }
          }

          &:nth-child(3) {
            flex: 0 0 17%;
            max-width: 17%;
          }

          &:nth-child(4) {
            flex: 0 0 9%;
            max-width: 9%;
          }

          &:nth-child(5) {
            flex: 0 0 9%;
            max-width: 9%;
          }

          &:nth-child(6) {
            flex: 0 0 8%;
            max-width: 8%;
          }

          &:nth-child(7) {
            flex: 0 0 12%;
            max-width: 12%;
          }

          &:nth-child(8) {
            flex: 0 0 6%;
            max-width: 6%;
            display: flex;
            justify-content: flex-end;

            .three-dot-dropdown {
              .dropdown-menu {
                width: 186px;
              }
            }
          }

        }

        &:last-child {
          justify-content: flex-end;
        }
      }

      .dropdown-wrapper {
        &.shared-profile {
          .user-icon {
            background-color: #e36f49;
            margin-right: 10px;
            font-size: 15px;
            min-width: 40px;
            width: 40px;
            height: 40px;
            box-shadow: none;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .right-sec {
            .dropdown-head {
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 4px;

              @include computer-max-1440 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 130px;
              }

            }

            .dropdown-des {
              font-size: 13px;
              color: rgba(90, 91, 94, 0.6);
            }
          }
        }
      }
    }

    .tooltip-container {
      .tooltip-text {
        min-width: 90px;
        width: max-content;
        max-width: 100%;
      }
    }


  }

  &.company-management-table {
    .table-head {
      margin-bottom: 5px;
    }

    .table-row {
      margin-bottom: 0;
      border-bottom: 0;
      background-color: transparent;
      backdrop-filter: none;
      padding: 12.5px 45px;

      .th,
      .td {
        color: $theme-black;

        &:nth-child(1) {
          flex: 0 0 24%;
          max-width: 24%;
          cursor: pointer;
        }

        &:nth-child(2) {
          flex: 0 0 24%;
          max-width: 24%;
        }

        &:nth-child(3) {
          flex: 0 0 12%;
          max-width: 12%;
        }

        &:nth-child(4) {
          flex: 0 0 12%;
          max-width: 12%;
        }

        &:nth-child(5) {
          flex: 0 0 12%;
          max-width: 12%;
        }

        &:nth-child(6) {
          flex: 0 0 8%;
          max-width: 8%;
        }

        &:nth-child(7) {
          flex: 0 0 8%;
          max-width: 8%;
          display: flex;
          justify-content: flex-end;

          .three-dot-dropdown {
            .dropdown-menu {
              width: 186px;
            }
          }
        }

        .status {
          &.active {
            color: $theme-success;
          }

          &.inactive {
            color: $text-error;
          }
        }

        .td-text {
          &.user-count {
            color: $theme-success;
            cursor: pointer;
          }
        }
      }

      .dropdown-wrapper {
        &.shared-profile {
          .user-icon {
            background-color: #e36f49;
            margin-right: 10px;
            font-size: 15px;

            &.object-user-icon {
              background-color: $white;

              img {
                object-fit: cover;
                border-radius: 0;
                width: 16px;
                height: 16px;
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .right-sec {
            .dropdown-head {
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 4px;
            }

            .dropdown-des {
              font-size: 13px;
              color: rgba(90, 91, 94, 0.6);
            }
          }
        }
      }
    }

  }

  &.object-management-table {

    .th,
    .td {
      color: $theme-black;

      &:nth-child(1) {
        flex: 0 0 25%;
        max-width: 25%;
      }

      &:nth-child(2) {
        flex: 0 0 25%;
        max-width: 25%;

        .td-text {
          &.category-name {
            max-width: 100%;
          }
        }
      }

      &:nth-child(3) {
        flex: 0 0 25%;
        max-width: 25%;
      }

      &:nth-child(4) {
        flex: 0 0 15%;
        max-width: 15%;
      }

      &:nth-child(5) {
        flex: 0 0 10%;
        max-width: 10%;
        display: flex;
        justify-content: flex-end;

        .three-dot-dropdown {
          .dropdown-menu {
            width: 186px;
          }
        }
      }

      .status {
        &.active {
          color: $theme-success;
        }

        &.inactive {
          color: $theme-black;
        }
      }
    }

    .dropdown-wrapper {
      &.shared-profile {
        display: flex;
        align-items: center;

        .user-icon {
          background-color: $theme-success !important;
          border: 0;

          img {
            object-fit: contain !important;
            height: 17px !important;
          }
        }

        .right-sec {
          .dropdown-head {
            margin-bottom: 0 !important;
            font-size: 14px;
          }
        }
      }
    }

  }

  &.onboard-user-table {
    .table-row {

      .th,
      .td {
        color: $theme-black;

        &:nth-child(1) {
          flex: 0 0 30%;
          max-width: 30%;
        }

        &:nth-child(2) {
          flex: 0 0 30%;
          max-width: 30%;

          .td-text {
            &.category-name {
              max-width: 100%;
            }
          }
        }

        &:nth-child(3) {
          flex: 0 0 20%;
          max-width: 20%;
        }

        &:nth-child(4) {
          flex: 0 0 10%;
          max-width: 10%;
        }


        &:nth-child(5) {
          flex: 0 0 10%;
          max-width: 10%;
          display: flex;
          justify-content: flex-end;

          .three-dot-dropdown {
            .dropdown-menu {
              width: 186px;
            }
          }
        }
      }

      .dropdown-wrapper {
        &.shared-profile {
          display: flex;
          align-items: center;

          .user-icon {
            .user-img {
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .right-sec {
            .dropdown-head {
              margin-bottom: 0 !important;
              font-size: 14px;
            }
          }
        }
      }

    }
  }


}

.ev-bp-table-inner {
  background-color: rgba(2, 114, 182, 0.87);
  box-shadow: 13.159px 13.159px 31.5px 0px rgba(24, 57, 87, 0.15);
  backdrop-filter: blur(14px);
  padding: 39px 68px 39px 32px;
  border-radius: 21px;

  .contest-table {
    .table-head {
      margin-bottom: 25px;

      .table-row {
        .th:last-child {
          justify-content: left;
        }
      }
    }

    .table-body {
      .table-row {
        height: 51px;
        min-height: 51px;
        background-color: transparent;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: none;
        border-bottom: 0px solid #e4ebed;
        padding: 20px 18px 14px 18px;
        margin-bottom: 0;
      }

      .td {
        padding: 0;
      }
    }

    .table-row {

      .th,
      .td {
        &:nth-child(1) {
          flex: 0 0 30%;
          max-width: 30%;
        }

        &:nth-child(2) {
          flex: 0 0 30%;
          max-width: 30%;
        }

        &:nth-child(3) {
          flex: 0 0 40%;
          max-width: 40%;
        }

      }
    }

  }

}




// Admin table start here
.admin-table-container {
  background-color: #e8eaef;
  box-shadow: 13px 13px 23px rgba(24, 57, 87, 0.15), -11px -11px 21px #f6f6f6;
  padding: 20px 0px 34px;
  height: 820px;

  .th {
    font-size: 12px;
    font-weight: 700;
    color: $theme-black;
    text-transform: uppercase !important;

    &:last-child {
      justify-content: flex-end;
    }
  }

  .td {
    font-weight: 400;
  }

  .shorting-btn {
    .shorting-logo {
      width: 8px;
      margin-left: 5px;
    }
  }

  .table-inner-wrap {
    margin-bottom: 48px;
  }
}

// Map View Table
