.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.not-allowed {
  cursor: not-allowed !important;
}

.blue-btn {
  background-color: #0172B6 !important;
}

.disabled-div {
  cursor: default !important;
  opacity: 0.5;
}

.disabled-btn {
  cursor: not-allowed !important;
  border: 0;
  box-shadow: none;
  opacity: 0.5;
}

.disabled-object {
  pointer-events: none;
}

.btn:disabled {
  cursor: not-allowed;
  pointer-events: inherit;
}

.hide {
  display: none;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  border: 0;
  box-shadow: none;
  opacity: 0.5;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.margin-b0 {
  margin-bottom: 0px !important;
}

.no-layers {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.padding-0 {
  padding: 0px !important;
}

.margin-r15 {
  margin-right: 15px !important;
}

.read-only {
  pointer-events: none;
}

.parameter-modal-backdrop {
  --bs-backdrop-zindex: none;
}

.cursor-move {
  cursor: move !important;
}

.error-field-select {
  .select__control {
    border-color: $text-error !important;
  }
}

.body_overflow {
  overflow: hidden;
}

.z-index-6 {
  z-index: 6 !important;
}

.user-icon-com {
  align-items: center;
  background-color: #e36f49;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 25px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  line-height: normal;
  margin-right: 12px;
  min-width: 50px;
  text-transform: uppercase;
  width: 50px;
}

.darkblue {
  background-color: #0172b6 !important;
}

.blue {
  background-color: #4387B0 !important;
}

.no-object {
  color: #ffffff;
  width: 100%;
  text-align: center;
  margin-top: 60px;
}

.question-list {
  justify-content: flex-end !important;

  .user-icon {
    position: relative;
    right: -65px;
    top: -144px;
  }

  .first-question {
    &.user-icon {
      top: -75px;
    }
  }
}

.gm-style .gm-style-iw-c .gm-ui-hover-effect.map-close-button {
  top: 50px !important;
  right: -166px !important;
  z-index: 2;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: $theme-black !important;
  box-shadow: 0 0 0 5px #c9cad0 !important;
}

.rc-slider-handle:hover {
  border-color: #a0a2a8 !important;
}

/* Custom styles for the rc-slider */
.custom-slider {
  height: 8px;
  margin: 20px 0;
}

.custom-slider .rc-slider-rail {
  background-color: rgba(0, 0, 0, 0.2);
  height: 1px;
  border-radius: 4px;
}

.custom-slider .rc-slider-track {
  background-color: $theme-black;
  height: 1px;
  border-radius: 4px;
}

.custom-slider .rc-slider-handle {
  width: 20px;
  height: 20px;
  border: 2px solid $theme-black;
  background-color: $theme-black;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin-top: -10px;
  /* Adjust this value based on the handle size */
  cursor: grab;
  opacity: 1;
}

.custom-slider .rc-slider-handle:active {
  cursor: grabbing;
}

.rc-slider-wrap {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    width: 150px;
    padding-right: 10px;
  }
}

.postion_relative {
  position: relative;
}

.lable-width-250 {
  width: 250px !important;
}

.dropzone {
  cursor: pointer;
  border: 1px dashed $theme-black;
  padding: 16px 20px;
  margin-bottom: 10px;
  p{
    text-decoration: underline;
    color: $theme-success;
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
  }
}
.uploaded-file-observation{
  .observation-img-wrap{
    position: relative;
    width: 90px;
    .uploaded-img-observation{
      width: 90px;
      height: 90px;
    }
    .observationi-cross-btn{
      width: 10px;
      height: 10px;
      position: absolute;
      right: 4px;
      top: 10px;
      cursor: pointer;
    }
    &::before{
      content: '';
      width: 90px;
      height: 90px;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

  }
}
.observation-file-wrap{
  display: flex;
  justify-content: space-between;
}

.observation-file-inner{
  flex: 1;
  .form-group{
    margin-bottom: 0 !important;
  }
}
.pl-10{
  padding-left: 10px !important;
}
.pr-10{
  padding-right: 10px !important;
}

.monte_form_group {
  position: relative;
}
