/* Tooltip container */
.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  width: 100%;
  background-color: $theme-black;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 5px;
  padding: 4px 12px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;

  &.up {
    top: -20px;
  }
}

/* Tooltip container on hover */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(-4px);
}

// Tooltip container End here

// Tost msg start here
.tost-msg-wrapper {
  background-color: $bg-error;
  min-height: 74px;
  min-width: 424px;
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  align-items: center;
  padding: 20px;

  &::before {
    content: "";
    width: 16px;
    height: 2px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
  }

  &.success {
    background-color: $tost-success;

    .icon-invalid {
      display: none;

      &::before {
        margin-right: 8px;
      }
    }

    .icon-success-tick {
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      &::before {
        content: "";
        background: url(../images/success-tick.svg) no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .tost-msg {
      margin-left: 28px;
    }
  }

  .icon-invalid {
    &::before {
      margin-right: 8px;
    }
  }

  .icon-success-tick {
    display: none;
  }

  .tost-msg {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }

  button {
    border: 0;
    background: transparent;
    margin-left: auto;

    &.icon-close-grey-icon {
      &::before {
        color: rgba(255, 255, 255, 0.5);
        display: inline-block;
        font-size: 12px;
      }
    }
  }
}

sup {
  top: 0;
  left: 0px;
  color: $text-error;
  font-size: 14px;
  font-weight: 700;
}

.drag-drop-main {
  .drag-drop-head {
    color: $white;
    margin-bottom: 0;
  }

  .drag-drop-main-wrap {
    padding: 12px 5px 5px 5px;
    // max-height: 200px;
    margin: 0 -5px 0 -5px;
    overflow-y: auto;
    width: 365px;
    max-width: 365px;

    .drag-drop-wrapper {
      display: flex;
      flex-flow: column wrap;
      height: 178px;
      margin: 0;
      padding: 0;
      align-content: flex-start;

      &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: rgb(255, 255, 255, 0.2);
      }

      .drag-drop-item {
        width: 88px;
        min-width: 88px;
        height: 88px;
        background-color: rgba(255, 255, 255, 0.31);
        backdrop-filter: blur(8.132118225097656px);
        list-style: none;
        margin-right: 1px;
        margin-bottom: 1px;
        padding: 14px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        transition: all 0.3s ease-out;
        position: relative;
        cursor: pointer;

        .drag-drop-img {
          height: 32px;
          width: 32px;
          max-height: 32px;
          transition: all 0.3s ease;
          filter: drop-shadow(0.4033997654914856px 0.48715755343437195px 0.8640375733375549px rgba(0, 0, 0, 0.07)) drop-shadow(1.774958848953247px 2.143493175506592px 1.7890660762786865px rgba(0, 0, 0, 0.11)) drop-shadow(4.356717586517334px 5.2613019943237305px 3.567966938018799px rgba(0, 0, 0, 0.14)) drop-shadow(8.390715599060059px 10.132877349853516px 6.993622303009033px rgba(0, 0, 0, 0.17)) drop-shadow(14.11899185180664px 17.050514221191406px 12.858912467956543px rgba(0, 0, 0, 0.21)) drop-shadow(21.783586502075195px 26.306509017944336px 21.95672035217285px rgba(0, 0, 0, 0.28));

          &.add-logo {
            width: 15px;
            height: 15px;
          }
        }

        .drag-drop-text {
          color: rgba(0, 0, 0, 0);
          font-size: 12px;
          font-weight: 300;
          line-height: normal;
          position: absolute;
          bottom: 6px;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          max-width: 80px;
          padding: 0 5px;

          &.new-object {
            color: #fff;
            text-align: right;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            display: block;
            margin-top: 7px;
            position: relative;
            bottom: initial;
          }
        }

        &:hover {
          scale: 1.1;
          z-index: 2;
          background-color: rgba(255, 255, 255, 0.51);
          backdrop-filter: blur(8.748615264892578px);
          transition: all 0.3s ease-out;

          .drag-drop-text {
            color: rgba(0, 0, 0, 1);
            z-index: 3;
          }

          .new-object {
            color: $white;
            z-index: 3;
          }

          .drag-drop-img {
            z-index: 2;
            transition: all 0.3s ease;
            margin-top: -20px;

            &.add-logo {
              width: 15px;
              height: 15px;
              margin-top: 0;
            }

          }
        }
      }
    }

  }
}

// no data section start here
.no-data-wrapper {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    display: inline-block;
    margin-top: 14px;
    color: #444;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  &.no-simulation-title {
    span {
      color: #fff;
    }
  }
}

.icon-success-tick {
  position: absolute;
  top: 10px;
  right: 23px;

  &::before {
    content: "";
    position: absolute;
    background: url(../images/theme-tick.svg) no-repeat;
    width: 13px;
    height: 10px;
  }
}

.section-title {
  color: #5a5b5e;
  font-size: 45px;
  font-weight: 300;
  line-height: normal;
}

.section-title-des {
  color: rgba(90, 91, 94, 0.7);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 46px;
}

.sub-head {
  color: $theme-black;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

// dsider tabs css
.tab-sec-thead {
  color: $theme-black;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  display: block;
}

.dsider-tab-wrapper {
  .nav-tabs {
    margin-bottom: 25px;
    border: 0;

    .nav-link {
      border-radius: 100px;
      border: 1px solid $theme-black;
      min-width: 92px;
      height: 35px;
      color: $theme-black;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 7px;
      background-color: transparent;
      opacity: 0.3;

      &.active {
        opacity: 1;
      }
    }
  }

  .tab-right-part {
    color: #868586;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;

    .create-modal-dropdown {
      position: relative;
      right: unset;
      top: unset;
      transform: none;
    }
  }

  .tab-main-container {
    .tab-main-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 18px;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 35px;
      }

      .tab-right-part {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .tab-date {
          color: #868586;
          text-align: right;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          margin-right: 20px;
        }
      }
    }
  }

  &.compare-modal-tab {
    padding-bottom: 30px;
    position: relative;

    .nav-tabs {
      display: inline-flex;
      height: 62px;
      border-top-left-radius: 21px;
      border-top-right-radius: 21px;
      margin-bottom: 0;
      .nav-item{
        margin-left: -8px;
        .nav-link {
          border-radius: 0;
          border: 0;
          height: 62px;
          padding: 20px;
          font-size: 18px;
          font-weight: 500;
          color: #000000;
          opacity: 1;
          margin-right: 0;
          min-width: 216px;
          border-top-left-radius: 21px;
          border-top-right-radius: 21px;
          transition: unset;
          text-transform: UPPERCASE;
          background-color: rgba(255, 255, 255, 0.72);
          box-shadow: 10px 0px 22px -6px rgba(0, 0, 0, 0.45);
          backdrop-filter: blur(25px);
          position: relative;

          &.active {
            background-color: rgb(16 113 172);
            backdrop-filter: none;
            color: $white;
            position: relative;
            box-shadow: none;
          }
        }
        &:nth-child(1){
          margin-left: 0;
          .nav-link {
            z-index: 10;
            &.active{
              z-index: 11;
            }
          }
        }
        &:nth-child(2){
          .nav-link {
            z-index: 9;
            &.active{
              z-index: 11;
            }
          }
        }
        &:nth-child(3){
          .nav-link {
            z-index: 8;
            &.active{
              z-index: 11;
            }
          }
        }
        &:nth-child(4){
          .nav-link {
            z-index: 7;
            &.active{
              z-index: 11;
            }
          }
        }
        &:nth-child(5){
          .nav-link {
            z-index: 6;
            &.active{
              z-index: 11;
            }
          }
        }
      }


    }

    .tab-content {
      .table-wrapper {
        background-color: transparent;
        backdrop-filter: blur(18.18181800842285px);
        padding: 0;
        border-radius: 0px 29px 25px 25px;
        border: 5px solid rgb(16, 113, 172);

        &.power-table-wrap {
          background-color: transparent;
          backdrop-filter: none;
          padding: 0px;
          border-radius: 0px 24px 24px 24px;
          border: 5px solid rgb(16, 113, 172);

          .simulation-chart-wrapper {
            margin-top: 0;
            border-top-left-radius: 0;

            .chart-title {
              font-size: 40px;
              font-weight: 400;
              margin-bottom: 26px;
            }

            .chart-left {
              border-top-left-radius: 0;
            }
          }
        }

        .section-head {
          color: var(--App-Black, #5A5B5E);
          font-size: 25px;
          font-weight: 400;
          margin-bottom: 34px;
          background-color: rgb(16, 113, 172);
          padding: 40px 34px;
        }

        .table-container {
          .table-head {
            color: $white;
            margin-bottom: 0;
            background-color: rgb(16, 113, 172);
            padding: 24px 40px;
            border-top-right-radius: 21px;

            .table-row {
              padding: 0;

              .th {
                color: $white;
              }
            }
          }

          .table-body {
            .table-row {
              background-color: transparent;
              backdrop-filter: none;
              border: none;
              padding: 24px 40px;

              &:nth-child(odd) {
                background-color: #bdd9e8;
                backdrop-filter: blur(10px);
              }

              &:nth-child(even) {
                background-color: #e6f0f6;
                backdrop-filter: blur(10px);
              }

              &:last-child {
                border-bottom: 0;
                border-bottom-right-radius: 21px;
                border-bottom-left-radius: 21px;
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .ev-bp-table-sec {
        .ev-bp-table-inner {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.map-view-table {
          width: 356px;

          .ev-bp-table-inner {
            width: 356px;
            height: 100%;
            max-height: 465px;
            border-radius: 21px;
            padding: 32px;

            .varticle-border {
              padding: 0;
            }
          }

        }
      }
    }

    iframe {
      width: 100% !important;
    }
  }
}

// three dot dropdown
.three-dot-dropdown {
  .dropdown-toggle {
    background-color: transparent !important;
    box-shadow: none !important;
    min-width: auto;
    padding: 0;
    margin: 0;
    height: auto;
    display: inline-block;

    &::after {
      border: 0;
    }
  }

  .dropdown-menu {
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px);
    width: 225px;
    border: 0;
    border-radius: 0;
    padding: 24px 20px 20px 20px;

    .dropdown-item-text {
      margin-bottom: 12px;
      padding: 0;
      color: rgba(90, 91, 94, 0.6);
      font-size: 12px;
      font-weight: 600;
      line-height: normal;
    }

    .dropdown-item {
      margin-bottom: 15px;
      padding: 0;
      color: $text-grey;
      font-weight: 400;
      font-size: 14px;

      &:hover {
        background-color: transparent;
        font-weight: 600;
      }

      &.active {
        background-color: transparent;
        font-weight: 600;

        color: $text-grey;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.user-circle {
  display: flex;
  align-items: center;
  margin-right: 35px;

  .user-icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 50%;
    margin-right: 6px;
    border: 1px solid #fff;
    background-color: #e36f49;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    .user-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      margin: 0;
      padding: 0;
      object-position: top;
    }
  }

  &.plus-img {
    .user-icon:nth-child(2) {
      margin-left: -8px;
    }

    .user-icon:nth-child(3) {
      margin-left: -8px;
    }

    .user-icon:nth-child(4) {
      margin-left: -8px;
    }

    .user-icon:nth-child(5) {
      margin-left: -8px;
    }

    .user-icon {
      margin-right: 0;

      &.five-plus {
        margin-left: -8px;
      }
    }
  }
}

.circle-logo-wrapper {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .svg-icon {
    display: flex;
  }

  &.delete-btn {
    background-color: $bg-danger;
  }

  &.active {
    .svg-icon {
      svg path {
        stroke: #7A3BC9;
      }
    }

    .icon {
      &::before {
        color: #7A3BC9;
      }
    }
  }
}

// Project card start here
.project-card-wrapper {
  .project-card-row {
    margin-left: -35px;

    .no-data-wrapper {
      height: inherit;
      margin-top: 40px;
    }
  }
}

.project-card {
  position: relative;
  transition: all 0.1s ease-in-out;
  width: 204px;
  height: 270px;
  margin-bottom: 5px;
  padding: 20px;

  &:hover {
    background-color: #fff;
    box-shadow: 13.15927px 13.15927px 23.4987px 0px rgba(24, 57, 87, 0.05),
      -10.8094px -10.8094px 21.14883px 0px rgba(246, 246, 246, 0.9);
    width: 204px;
    z-index: 2;

    // margin-left: -35px;
    .project-card-img {
      width: 85px;
      margin-bottom: 15px;
      transition: all 0.1s ease-in-out;
      cursor: pointer;
    }

    .project-card-content {
      cursor: pointer;

      .project-card-title {
        margin-bottom: 4px;
        -webkit-line-clamp: 2;
      }

      .tooltip-container {
        display: inline-block !important;
      }

      .project-card-source {
        margin-bottom: 20px;
      }

      .project-card-date {
        position: absolute;
        bottom: 20px;
        transition: all 0.1s ease-in-out;
      }
    }

    .card-dropdown {
      display: block !important;
    }
  }

  .project-card-img {
    margin-bottom: 15px;
    width: 154px;
    transition: all 0.1s ease-in-out;

    .card-img {
      width: 100%;
    }
  }

  .project-card-content {
    .project-card-title {
      color: $theme-black;
      font-size: 16px;
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
      margin-bottom: 3px;
    }

    .project-card-source {
      color: $theme-black;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    .project-card-date {
      color: #868586;
      font-size: 12px;
      font-weight: 500;
      transition: all 0.3s ease-in-out;
    }

    .tooltip-container {
      display: none;

      .tooltip-text {
        max-width: 185px;
        padding: 8px;
        font-size: 11px;
        top: 30%;
        left: 20%;
        transform: none;
        text-align: left;
      }

      .project-card-des {
        color: $theme-black;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 6px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        transition: all 0.1s ease-in-out;
      }
    }
  }

  .card-dropdown {
    position: absolute;
    top: 15px;
    right: 20px;

    .three-dot-dropdown {
      .dropdown-menu {
        width: 140px;
        top: -7px;
      }
    }
  }
}

.divider-rule {
  position: relative;
  margin-left: 6px;

  &::before {
    content: "";
    position: absolute;
    width: 1.4px;
    height: 12px;
    max-width: 10px;
    background-color: rgba(90, 91, 94, 0.8);
    margin-left: -5px;
    top: 3px;
  }
}

.single-project-wrapper {
  .tab-sec-thead {
    margin-bottom: 20px;
  }

  .tab-main-wrapper {
    .tab-left-part {
      width: calc(100% - 70px);

      .right-sec {
        .dropdown-des {
          color: #868586;
        }
      }
    }

    .tab-right-part {
      width: 140px;

      .back-project {
        .icon-next-arrow-grey {
          margin-right: 15px;

          &::before {
            font-size: 12px;
            color: rgba(90, 91, 94, 0.5);
          }
        }
      }
    }

  }
}

.create-scenario {
  color: $theme-success;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 33px;
}

.create-plus-icon {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  filter: drop-shadow(13.159272193908691px 13.159272193908691px 23.49869728088379px rgba(24, 57, 87, 0.15)) drop-shadow(-10.809399604797363px -10.809399604797363px 21.148826599121094px #f6f6f6);
  position: relative;

  &::before {
    content: "";
    background: url(../images/create-plus.svg) no-repeat;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.data-card-wrapper {
  display: flex;
  position: relative;

  .data-card-wrap {
    position: relative;

    .data-card-main {
      background-color: rgba(64, 108, 186, 0.9);
      filter: drop-shadow(0px 2.943873882293701px 14.719369888305664px rgba(0, 0, 0, 0.21));
      backdrop-filter: blur(29.438739776611328px);
      height: 185px;
      padding: 14px 20px 15px 20px;
      margin-right: 0px;
      position: relative;
      z-index: 1;
      margin-bottom: 12px;

      .data-card-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 4px;

        .user-circle {
          flex-direction: row-reverse;
          margin-right: 16px;

          .user-icon {
            width: 22px;
            height: 22px;
            min-width: 22px;
            margin-left: -6px;
            z-index: 0;

            &:nth-child(2) {
              margin-left: -6px;
              z-index: 1;
            }

            &:nth-child(3) {
              margin-left: -6px;
              z-index: 2;
            }

            &:nth-child(4) {
              margin-left: -6px;
              z-index: 3;
            }

            &:nth-child(5) {
              margin-left: -6px;
              z-index: 4;
            }

            &:nth-child(6) {
              margin-left: -6px;
              z-index: 5;
            }

            &:nth-child(7) {
              margin-left: -6px;
              z-index: 6;
            }

            &:nth-child(8) {
              margin-left: -6px;
              z-index: 7;
            }

            &:nth-child(9) {
              margin-left: -6px;
              z-index: 8;
            }

            &:nth-child(10) {
              margin-left: -6px;
              z-index: 9;
            }

            &:nth-child(11) {
              margin-left: -6px;
              z-index: 10;
            }

            &:nth-child(12) {
              margin-left: -6px;
              z-index: 11;
            }

            &:nth-child(13) {
              margin-left: -6px;
              z-index: 12;
            }

            &:nth-child(14) {
              margin-left: -6px;
              z-index: 13;
            }

            &:nth-child(15) {
              margin-left: -6px;
              z-index: 14;
            }

            &:nth-child(16) {
              margin-left: -6px;
              z-index: 15;
            }

            &:nth-child(17) {
              margin-left: -6px;
              z-index: 16;
            }

            &:nth-child(18) {
              margin-left: -6px;
              z-index: 17;
            }

            &:nth-child(19) {
              margin-left: -6px;
              z-index: 18;
            }

            &:nth-child(20) {
              margin-left: -6px;
              z-index: 19;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: top;
            }
          }
        }

        .card-dropdown {
          display: flex;
          align-items: center;

          .dropdown-toggle {
            .icon-horizontal-three-dot {
              &::before {
                color: rgba(255, 255, 255, 0.6);
                font-size: 4px;
              }
            }
          }

          .dropdown-menu {
            width: 190px;
            padding: 20px;
            background-color: rgba(255, 255, 255, 0.9);
          }
        }
      }

      .data-card-body {
        z-index: 3;
        position: relative;
        height: 113px;
        cursor: pointer;

        &:hover {
          .card-hover-box {
            display: block;
          }
        }

        .modal-name {
          color: $white-opacity-6;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 4px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .data-card-title {
          color: $white;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .list-item-wrapper {
          margin-bottom: 3px;
        }
      }

      .card-hover-box {
        display: none;
        background-color: rgba(255, 255, 255, 0.75);
      }


      .data-card-footer {
        display: flex;
        justify-content: flex-end;

        .card-dropdown {
          height: 22px;

          .dropdown-menu {
            padding: 5px 0px;
            left: inherit !important;
            right: -10px !important;

            @include computer-max-1440 {
              right: -50px !important;
            }

            .dropdown-menu-inner {
              max-height: 192px;
              overflow-y: auto;
              padding: 21px 16px 11px 16px;
              margin-right: 3px;


              &::-webkit-scrollbar-thumb {
                background-color: $theme-black;
              }

              // If its not working on firefox then add &
              scrollbar-width: thin;
              scrollbar-color: $theme-black transparent;

              .dropdown-item {
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .dropdown-item-text {
              font-size: 13px;
              margin-bottom: 20px;
            }
          }

          .dropdown-item {
            &:hover {
              font-weight: 500;
              color: $text-grey;
            }

            .dropdown-wrapper {
              align-items: center;
              cursor: default;

              .user-icon {
                min-width: 30px;
                width: 30px;
                height: 30px;
                border: 1px solid #fff;
                margin-right: 8px;

                .user-img {
                  object-fit: contain;
                  padding: 4px;
                }
              }

              .right-sec {
                display: flex;

                .dropdown-head {
                  font-size: 14px;
                  font-weight: 500;
                  margin-bottom: 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 143px;

                  &.current-user-head {
                    max-width: 120px;
                  }
                }

                .current-user {
                  margin-left: 5px;
                  font-size: 14px;
                  font-weight: 500;
                }
              }
            }
          }

          .show {
            .active {
              background: rgba(0, 0, 0, 0.28);
            }
          }

          .card-view {
            border-radius: 30px;
            padding: 2px 7px 2px 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 22px;
            margin-right: 6px;
            background-color: rgba(0, 0, 0, 0);

            &.active {
              background-color: rgba(0, 0, 0, 0.28);
            }

            .card-view-img {
              margin-right: 5px;
              margin-bottom: 2px;
              width: 15px;
            }

            .card-view-number {
              color: $white-opacity-6;
              font-size: 12px;
              font-weight: 700;
            }
          }
        }

        .back-project {
          cursor: pointer;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: rgba(25, 56, 109, 0.2);
            border-radius: 50%;
          }

          .icon-next-arrow-grey {
            width: 22px;
            height: 22px;
            min-width: 22px;
            border-radius: 50%;
            object-fit: contain;
            object-position: top;
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
              font-size: 11px;
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }

      &.data-card-2 {
        background-color: rgba(6, 116, 171, 0.9);
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.21));
        backdrop-filter: blur(40px);
      }

      &.data-card-3 {
        margin-right: 0;
        background-color: rgba(16, 121, 148, 0.89);
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.21));
        backdrop-filter: blur(40px);
      }

      &.data-card-ev-1 {
        background-color: rgba(55, 151, 119, 0.90);
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.21));
        backdrop-filter: blur(40px);
      }

      &.data-card-ev-2 {
        background-color: rgba(44, 150, 145, 0.90);
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.21));
        backdrop-filter: blur(40px);
      }

      &.data-card-ev-3 {
        margin-right: 0;
        background-color: rgba(55, 134, 151, 0.90);
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.21));
        backdrop-filter: blur(40px);
      }
    }

    .card-created-date {
      display: block;
      color: $white-opacity-6;
      font-size: 10px;
      font-weight: 600;
    }

    .card-hover-box {
      background-color: rgba(255, 255, 255, 0.75);
      backdrop-filter: blur(10px);
      width: 383px;
      padding: 20px;
      position: absolute;
      left: 0px;
      bottom: 45px;
      z-index: 10;
      display: none;

      @include computer-max-1440 {
        left: -56px;
      }

      @include laptop-max-1366 {
        left: -80px;
      }

      &.show {
        display: block;
      }

      .card-hover-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .card-hover-img-wrap {
          width: 35px;
          height: 35px;
          display: inline-block;
          margin-right: 8px;

          .card-hover-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top;
          }
        }

        .card-header-right {
          .card-hover-head {
            color: $text-grey;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 6px;
          }

          .card-hover-subhead {
            font-size: 14px;
            font-weight: 500;
            display: block;
            color: rgba(68, 68, 68, 0.6);
          }
        }
      }

      .card-hover-body {
        @include max-height-700 {
          max-height: 180px;
          overflow-y: auto;
          margin: 5px -15px 5px 0;
        }

        .list-item-wrapper {
          margin-bottom: 16px;
          padding-right: 15px;

          .list-item {
            font-size: 13px;
            color: $text-grey;
            margin-bottom: 10px;

            &::before {
              width: 10px;
              height: 9px;
              top: 4px;
              background: url(../images/bullet-black.svg) no-repeat;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .card-hover-footer {
        .btn-outline {
          &.no-radius {
            width: 100%;
            display: flex;
            align-items: center;
            color: $text-grey;

            &:hover {
              color: $text-grey;
            }

            .icon-go-simulation-icon {
              margin-right: 7px;

              &::before {
                font-size: 18px;
              }
            }
          }
        }
      }

      .icon-dropdown-triangle {
        position: absolute;
        bottom: -11px;
        right: 75px;
        transform: rotate(0deg);
      }
    }
  }
}

// Ul Li css
.list-item-wrapper {
  padding: 0;

  .list-item {
    color: $white;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    padding-left: 18px;
    margin-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;

    &::before {
      content: "";
      background: url(../images/bullet-icon.svg) no-repeat;
      position: absolute;
      width: 9px;
      height: 8px;
      left: 0;
      top: 3px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

ul {
  li {
    list-style: none;
  }
}

// Request Dropdown
.request-dropdown {
  width: 94px;
  margin-right: 47px;
  position: relative;
  font-family: "Urbanist", sans-serif !important;

  &.pending-request {

    &::after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      top: -4px;
      right: -6px;
      background-color: #fa7575;
      border-radius: 50%;
    }
  }

  &.icon-white-arrow {
    &::before {
      font-size: 10px;
      position: absolute;
      left: 10px;
      font-family: "icomoon" !important;
    }
  }

  .dropdown-toggle {
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #fff;
    padding: 7px 10px 9px 28px;
    font-family: "Urbanist", sans-serif !important;

    &::after {
      border: none;
    }
  }

  .dropdown-menu {
    width: 401px;
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(25px);
    padding: 30px 23px 40px;
    position: absolute;
    right: -96px;
    top: 51px;
    left: inherit;
    border: 0;
    border-radius: 0;

    &::before {
      content: "";
      background: url(../images/dropdown-trigle.svg) no-repeat;
      position: absolute;
      top: -10px;
      right: 30%;
      width: 20px;
      height: 20px;
    }

    .custom-dropdown {
      h2 {
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 25px;

        &.my-modal-count {
          margin-left: 5px;
          display: inline-block;
        }
      }

      .right-sec {
        .dropdown-head {
          font-size: 14px;
          margin-bottom: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        .dropdown-des {
          font-size: 14px;
          width: calc(100% - 40px);

          .dropdown-des-bold {
            font-weight: 500;
            display: inline-block;
          }
        }
      }

    }
  }
}

// Hover Box start here
.hover-box-wrap {
  padding: 20px 6px 20px 20px;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(10px);
  width: 223px;

  .hover-box {
    max-height: 246px;
    overflow-y: auto;
    padding-right: 12px;

    .hover-box-inner {
      padding: 0;
      margin-bottom: 0;
    }

    .disable-text {
      color: $theme-black;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 15px;
      display: block;

      @include max-height-650 {
        font-size: 10px;
        margin-bottom: 12px;
      }
    }

    .hover-box-item {
      color: $theme-black;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
      cursor: pointer;

      @include max-height-650 {
        font-size: 12px;
        margin-bottom: 16px;
      }

      &:hover {
        font-weight: 600;
      }

      &.hover-box-divider {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 15px;
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.comment-box-wrap {
    width: 282px;
    cursor: default;
  }
}

.text-danger {
  color: $text-danger !important;
}

.dropdown-item {
  &:hover {
    font-weight: 600;
  }
}

.error-msg {
  color: $text-error;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
  bottom: -16px;
  position: absolute;
  right: 0;
}

.success-msg {
  color: green;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
  bottom: -16px;
  position: absolute;
  right: 0;
}

.empty-modal-wrapper {
  display: flex;
  align-items: center;

  .empty-modal-img {
    margin-right: 7px;
  }

  .empty-modal-text {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.output-value {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.method-wrapper {
  .method-text {
    font-size: 20px;
    font-weight: 500;
  }
}

// Google Map
.gootle-map-wrap {
  height: 277px !important;
  margin-bottom: 30px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  border: 1px solid $theme-black;

  .place-search-box {
    right: 60px;
  }
}

.margin-bottom {
  margin-bottom: 20px;
}

.divider-border {
  border-bottom: 1px solid rgba(90, 91, 94, 0.2);
}

.object-dropdown {
  cursor: pointer;
}

.object-dropdown-wrap {
  position: relative;
}

.show-hide-dropdown-wrap {
  top: 25px;
  position: absolute;
  right: 0;
  padding: 20px 6px 20px 20px;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(10px);
  width: 223px;

  .hover-box {
    max-height: 140px;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      background-color: $theme-black;
    }

    .disable-text {
      margin-bottom: 12px;
      display: block;
      font-size: 13px;
      font-weight: 600;
      color: rgba(90, 91, 94, 0.6);
    }

    .hover-box-item {
      font-size: 15px;
      margin-bottom: 15px;
      font-weight: 400;
      color: $text-grey;
      cursor: pointer;

      &:hover {
        font-weight: 500;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.all-object-dropdown-wrap {
  display: inline-block;
}

.no-sim-data {
  display: block;
  margin-bottom: 25px;
  text-align: center;
}

.red-text {
  color: #E17373 !important;
}

.green-text {
  color: #51A14A !important;
}

.dashboard-graph-data-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
}

// user info card
.user-card-main-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  .user-info-card {
    background-color: #E8EAEF;
    box-shadow: 13.159px 13.159px 23.499px 0px rgba(24, 57, 87, 0.15), -10.809px -10.809px 21.149px 0px #F6F6F6;
    padding: 32px;
    width: 225px;

    &.user-activity-card {
      width: 100%;
      padding: 16px 24px 20px;
    }

    .user-info-card-title {
      color: $theme-success;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .user-card-body {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .user-card-active {
        .user-card-sec-head {
          color: $theme-black;
          text-align: center;
          font-size: 32px;
          font-weight: 500;
          margin-bottom: 0;
        }

        .user-status-des {
          color: $theme-black;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }

    }

  }

}

.user-icon {
  &.orange {
    background-color: $theme-orange !important;
  }
}

.add-approver-wrap {
  border-radius: 6px;
  border: 1px dashed $theme-success;
  background: $white;
  padding: 20px;
  margin-bottom: 16px;
  cursor: pointer;
  height: 61px;

  .add-approver-des {
    color: $theme-success;
    font-size: 16px;
    font-weight: 400;
  }

  &.self-approval {
    background-color: #51A14A;
    border-color: #51A14A;

    .add-approver-des {
      color: $white;
      display: flex;
      align-items: center;

      .green-tick-circle {
        margin-right: 8px;
        display: inline-block;
      }
    }
  }

  &.baseline-approval {
    background-color: $theme-success;
    border-color: $theme-success;
  }
}

.pdf-close-btn {
  background-color: transparent;

  &:hover {
    background-color: #424649;
  }
}
.map-view-wrap{
  background: rgb(16, 113, 172);
  border-radius: 0 24px 24px;
  border: 5px solid rgb(16, 113, 172);
  > div {
    border-radius: 0 21px 21px;
  }
}
.compare-s-sec{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.graph-s-num{
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  color: $theme-black;
  margin-right: 12px;
  margin-bottom: 7px;
}
.graph-s-text{
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  color: $theme-black;
  padding-left: 2px;
}

