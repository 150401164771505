// React Flow css starts from here
.reactflow-main-container {
  .react-flow {
    display: flex;
    justify-content: center;
    border: 1px solid transparent;

    .react-flow__handle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid $react-flow-border;
      opacity: 0;

      &.edge-connected {
        opacity: 1;
      }

      &.react-flow__handle-right {
        right: -12px;
        background-color: #7A3BC9;
        border: 1px solid #ffffff;
      }

      &.react-flow__handle-left {
        top: 50%;
        left: -12px;
        transform: translate(0, -50%);
      }

    }
  }


  .react-flow__node-input,
  .react-flow__node-default,
  .react-flow__node-output,
  .react-flow__node-custom {
    border-color: $white;
  }

  .react-flow__node-default,
  .react-flow__node-input,
  .react-flow__node-output,
  .react-flow__node-group,
  .react-flow__node-dsiderNode {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222;
    text-align: center;
    background-color: #0172B6;
    -webkit-box-shadow: 0px 0px 0px 2px #0172B6;
    -moz-box-shadow: 0px 0px 0px 2px #0172B6;
    box-shadow: 0px 0px 0px 2px #0172B6;
    filter: drop-shadow(13.159272193908691px 13.159272193908691px 23.49869728088379px rgba(24, 57, 87, 0.15)) drop-shadow(-10.809399604797363px -10.809399604797363px 21.148826599121094px #F6F6F6);
    border: 1px solid #fff;

    &:hover {
      .node-container {
        border: 1px solid #7A3BC9;
        padding: 24px 22px;

      }

      .react-flow__handle {
        opacity: 1;
      }
    }

    &.disabled {
      .node-container {
        opacity: 0.5;
      }
    }
  }

  &.light-theme {

    .react-flow__node-default,
    .react-flow__node-input,
    .react-flow__node-output,
    .react-flow__node-group,
    .react-flow__node-dsiderNode {
      background-color: #E8EAEF;
      -webkit-box-shadow: 0px 0px 0px 2px #7A3BC9;
      -moz-box-shadow: 0px 0px 0px 2px #7A3BC9;
      box-shadow: 0px 0px 0px 2px #7A3BC9;
    }
  }

  .react-flow__node-default.selectable {
    &:hover {
      -webkit-box-shadow: 0px 0px 0px 2px #0172B6;
      -moz-box-shadow: 0px 0px 0px 2px #0172B6;
      box-shadow: 0px 0px 0px 2px #0172B6;
    }

  }

  .react-flow__controls {
    box-shadow: none;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px;
    padding: 0 20px 15px;
  }

  .react-flow__panel {
    &.react-flow__attribution {
      &.bottom {
        &.right {
          display: none;
        }
      }
    }

    &.react-flow__controls {
      justify-content: space-between;
      right: inherit;
      left: 0;
      width: 100%;

      .workbench-footer-right {
        display: flex;
        align-items: center;
      }
    }

    .zoomed-percentage {
      backdrop-filter: blur(10px);
      border: 0;
      position: relative;
      font-size: 12px;
      color: #838383;
      font-weight: 400;
      border-radius: 28px;
      padding: 12px 16px;
      margin-right: 16px;
      background-color: rgba(255, 255, 255, 0.5);
      width: 112px;
      height: 38px;
      display: flex;
      justify-content: center;
      transition: opacity 1.5s, transform 1.5s;

      &.default-zoom {
        opacity: 0;
        transition: opacity 1.5s, transform 1.5s;
      }
    }
  }

  .react-flow__controls-button {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 6px;
    border-bottom: 0;

    &:disabled {
      pointer-events: inherit;
      cursor: no-drop;
    }

    &:hover {
      .setting-btn {
        .circle-logo-wrapper {

          .icon-zoom-out,
          .icon-zoom-in {
            &::before {
              color: #7A3BC9;
            }
          }
        }
      }
    }

    .zoomed-in,
    .zoomed-out {

      .icon-zoom-out,
      .icon-zoom-in {
        &::before {
          color: #7A3BC9;
          font-size: 0;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

}

.pointer-none {
  pointer-events: none;
}

.workbench-loading {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 9;
}

.droppable {
  border: 1px dashed #4387B0 !important;
  background-color: rgba(67, 135, 176, 0.10);
}

.preview-container {
  padding: 5px;
  height: 82px;
  width: 82px;
  border-radius: 41px;
  opacity: 0;
  display: flex;
  background-color: rgba(255, 255, 255, 0.51);
  backdrop-filter: blur(2.132118225097656px);
  list-style: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;

  .custom-drag-preview {
    display: flex;
    background-color: #b5b2b2;
    backdrop-filter: blur(2.132118225097656px);
    list-style: none;
    margin-right: 1px;
    margin-bottom: 1px;
    padding: 14px 0px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    transition: all 0.3s ease-out;
    position: relative;
    border-radius: 41px;
    width: 72px;
    height: 72px;
    font-size: 10px;
    color: #222;
    -webkit-box-shadow: 0px 0px 0px 2px #ffffff;
    -moz-box-shadow: 0px 0px 0px 2px #ffffff;
    box-shadow: 0px 0px 0px 2px #ffffff;
  }
}

.custom-cursor {
  cursor: url('../images/cursor-message.png'), auto;
}

.node-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid rgba(90, 91, 94, 0.15);

  .layer-icon {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #7A3BC9;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -6px;
    top: -10px;
  }

  img {
    margin-top: 0px;
  }

  .object-label {
    width: 225%;
    font-size: 12px;
    display: block;
    position: absolute;
    max-width: 70px;
    top: 77px;
  }

  &.node-active {
    .object-icon {
      scale: 1.2;
    }

    border-radius: 50%;
    background-color: #0172B6;
    animation: openFolder 0.5s ease forwards;
  }

  @keyframes openFolder {
    from {
      height: 65px;
      width: 65px;
      opacity: 0.5;
    }

    to {
      height: 250px;
      width: 250px;
      opacity: 0;
    }
  }

}

.last-update-btn {
  color: #838383;
  font-size: 12px;
  font-weight: 400;
  background-color: #DBDEDF;
  width: auto;
  height: 30px;
  padding: 7px 15px;
  border: 0;
  border-radius: 30px;

  span {
    min-width: auto;
    display: inline-block;
  }

  &.computation-btn {
    border-radius: 32px;
    background: rgba(255, 255, 255, 0.31);
    backdrop-filter: blur(10px);
    width: 165px;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
  }
}
.circle-logo-wrapper{
  .icon-zoom-in, .icon-zoom-out{
    font-size: 0;
  }
  &:hover{
    .icon-zoom-in, .icon-zoom-out{
      filter: invert(34%) sepia(54%) saturate(1386%) hue-rotate(237deg) brightness(86%) contrast(108%);
    }
  }
}


// React Flow css ends from here
