.Toastify__toast-container {
  min-height: auto;
  width: 424px;
  position: fixed;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1090;
  .Toastify__toast {
    transition: none !important;
    border-radius: 0px;
    box-shadow: 0px 4px 14px 0px rgba(57, 207, 165, 0.1);
    margin: 0 !important;
    padding: 25px 50px 25px 50px;
    min-height: auto;
    font-family: $font-primary !important;
    position: relative;
    &::after {
      content: "";
      background: url("../images/tost-close.svg") no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 17px;
      top: 28px;
    }
    .Toastify__toast-icon {
      display: none;
    }
    &.Toastify__toast--success {
      border-radius: 16px;
      background: rgba(1, 114, 182, 0.7);
      backdrop-filter: blur(34px);
      &::before {
        content: "";
        background: url("../images/success-tick.svg") no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 20px;
        top: 23px;
      }
    }
    &.Toastify__toast--error {
      padding-left: 48px;
      border-radius: 16px;
      background: rgba(229, 77, 77, 0.7);
      backdrop-filter: blur(34px);
      &::before {
        content: "";
        background: url("../images/invalid.svg") no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 20px;
        top: 23px;
      }
    }
    .Toastify__toast-body {
      padding: 0;
      > div {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .Toastify__close-button {
    display: none;
  }
}

// Loader
._loading_overlay_overlay {
  position: fixed !important;
  height: 100vh !important;
  background-color: rgba(212, 212, 212, 0.2) !important;
  z-index: 9999 !important;
  ._loading_overlay_content {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    backdrop-filter: blur(10px);
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  ._loading_overlay_spinner {
    margin: 14px auto 0;
    width: 36px;
    svg {
      circle {
        stroke: $theme-black;
        stroke-width: 2;
      }
    }
  }
}
