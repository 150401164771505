// WorkBench css
.workbench-dashboard-main {
  background-size: cover;
  background-position: center center;
  padding-top: 120px;
  padding-bottom: 35px;
  min-height: 100vh;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;

  &.workbench-bg {
    background: url(../images/main-bg.png) no-repeat;
    background-size: cover;
  }

  &.EV-workbench-bg {
    background: url(../images/ev-workbench-bg.png) no-repeat;
    background-size: cover;
    background-position: center center;
  }

  @include max-height-768 {
    padding-top: 120px;
    padding-bottom: 45px;
  }

  header {
    .workbench-header {
      .container {
        .nav-dropdown-wrapper {
          .request-dropdown {
            margin-right: 56px !important;
          }
        }
      }

      .nav-dropdown-wrapper {
        .close-btn {
          position: static;
        }
      }
    }
  }

  &::after {
    content: "";
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    min-height: 100vh;
  }

  .workbench-dashboard-inner {
    padding: 0 81px;
    width: 100%;
    z-index: 2;
    position: relative;
  }

  .dashboard-main-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .dashboard-left {
      margin-right: 0px;
      width: 360px;

      .custom-select-main {
        .custom-select-wrp {
          .select__menu {
            .select__menu-list {
              max-height: 240px;
            }
          }
        }
      }
    }

    .dashboard-right {
      width: calc(100% - 360px);
      padding-left: 46px;
    }
  }

  &.light-bg-theme {
    background: rgba(232, 234, 236, 0.6);

    &::after {
      background: transparent;
    }

    header {
      .workbench-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);

        .container {
          .navbar-nav {
            .background-link {
              color: $theme-black;
            }

            &.nav-dropdown-wrapper {
              .close-btn {
                margin-left: 0px;

                .icon-close-grey-icon {
                  &::before {
                    color: $theme-black;
                    font-size: 18px;
                  }
                }
              }
            }

            .header-close-icon {
              img {
                filter: invert(60%) sepia(2%) saturate(550%) hue-rotate(187deg)
                  brightness(91%) contrast(91%);
              }
            }
          }
        }
      }
    }

    .workbench-dashboard-inner {
      .dashboard-main-wrapper {
        .home-header-sec {
          .dropdown-toggle {
            &::before {
              background: url(../images/all-project-dark.svg) no-repeat;
            }
          }
        }

        .section-title {
          color: $theme-black;
          text-shadow: none;
        }

        .select__single-value,
        .link-white-icon,
        .dropdown-toggle,
        .new-object,
        .no-object {
          color: $theme-black !important;
        }

        .select__control {
          border-color: $theme-black !important;
        }

        .select__dropdown-indicator {
          &::after {
            color: $theme-black !important;
          }
        }

        .drag-drop-head {
          color: $theme-black;
        }

        .drag-drop-item {
          border: 1px solid #d0d1d1 !important;
          margin-right: 0 !important;
          margin-bottom: 0px !important;
          background-color: transparent !important;
          backdrop-filter: none;

          &:hover {
            background-color: #f1f2f4 !important;
          }

          .drag-drop-img {
            filter: none;
          }
        }

        .drag-hint-wrapper {
          .drag-hint-text {
            color: rgba(90, 91, 94, 0.7) !important;
          }

          .icon-dragdrop-icon {
            &::before {
              color: rgba(90, 91, 94, 0.5) !important;
            }
          }
        }

        .dashboard-left {
          .object-select {
            .custom-search {
              background-color: #5a5b5e;
            }
          }

          .custom-select-main.object-select {
            .search-object-form-group {
              .form-control {
                border-bottom: 1px solid $theme-black;
                color: $theme-black;

                &::placeholder {
                  color: $theme-black !important;
                }

                &::-webkit-input-placeholder {
                  color: $theme-black !important;
                }

                /* Specific placeholder color for Firefox 4-18 */
                &:-moz-placeholder {
                  color: $theme-black !important;
                }

                /* Specific placeholder color for Firefox 19+ */
                &::-moz-placeholder {
                  color: $theme-black !important;
                }

                /* Specific placeholder color for Internet Explorer 10+ */
                &:-ms-input-placeholder {
                  color: $theme-black !important;
                }
              }

              .icon-close-grey-icon {
                &::before {
                  color: $theme-black;
                  font-size: 14px;
                }
              }
            }
          }
        }

        .workbench-chart-header {
          background-color: #e2e4e8 !important;

          .dsider-scenario-dropdown {
            .dropdown-toggle {
              color: $white !important;
            }
          }
        }

        .work-board-text {
          color: $theme-black !important;
        }

        .workbench-chart-main {
          backdrop-filter: none !important;
          background-color: #eff0f2;
          filter: drop-shadow(
              13.159272193908691px 13.159272193908691px 25.5px
                rgba(147, 168, 210, 0.13)
            )
            drop-shadow(
              -10.809399604797363px -10.809399604797363px 24.149999618530273px
                #f6f6f6
            );
        }

        .icon-home-white {
          &::before {
            color: $theme-black !important;
          }
        }

        .editable-input-form {
          .form-group {
            textarea {
              color: $theme-black;
            }

            .max-char {
              color: $theme-black;
            }
          }
        }

        .last-update-btn {
          &.computation-btn {
            background-color: rgba(90, 91, 94, 0.2);
            color: $theme-black;
          }
        }
      }
    }
  }
}

.home-header-sec {
  margin-bottom: 30px;
  display: flex;

  .link-white-icon {
    color: $white;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    &.all-project-dropdown {
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 21px;
        background-color: #cdcdcd;
        left: -11px;
      }
    }

    &:first-child {
      margin-right: 20px;
      margin-left: -15px;
      position: relative;
      height: 32px;
      background-color: transparent;
      border-radius: 30px;
      padding: 8px 15px 9px 15px;
      border: 0;

      &:hover {
        background-color: rgba(255, 255, 255, 0.11);
        backdrop-filter: blur(10px);
      }
    }

    .icon-home-white {
      margin-right: 7px;

      &::before {
        font-size: 17px;
      }

      &.icon-all-project-icon {
        &::before {
          font-size: 15px;
        }
      }
    }
  }

  .request-dropdown {
    &.all-project-dropdown {
      .dropdown-toggle {
        color: $white;
        font-size: 12px;
        font-weight: 700;
        background-color: transparent;
        border-radius: 30px;
        padding: 8px 15px 9px 40px;
        border: 0;
        position: relative;

        &.show {
          background-color: rgba(255, 255, 255, 0.11);
          backdrop-filter: blur(10px);
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.11);
          backdrop-filter: blur(10px);
        }

        &::before {
          content: "";
          background: url(../images/all-project-icon.svg) no-repeat;
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 15px;
        }
      }

      &::after {
        display: none;
      }

      .dropdown-menu {
        width: 324px;
        top: 10px !important;
        padding: 30px 23px 0px;

        &::before {
          left: 35px;
          right: initial;
        }

        .custom-dropdown {
          max-height: 300px;
          overflow-y: auto;
          margin-right: -18px;
          padding-right: 15px;
          padding-bottom: 25px;

          &::-webkit-scrollbar {
            width: 3px;
          }

          h2 {
            font-size: 14px;
            margin-bottom: 5px;
            text-transform: capitalize;

            &.shared-head {
              margin-top: 12px;
            }
          }

          .my-modal-count {
            color: $theme-black;
            font-size: 12px;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 6px;
          }

          .dropdown-wrapper {
            padding-bottom: 0;
            border-bottom: 0;

            .dsider-tab-wrapper {
              width: 100%;
              display: flex;
              flex-direction: column;

              .tab-main-container {
                .tab-main-wrapper {
                  padding-bottom: 14px;
                  margin-top: 15px;
                  align-items: flex-start;

                  &:last-child {
                    padding-bottom: 0;
                  }
                }
              }
            }

            .user-icon {
              min-width: 32px;
              width: 32px;
              height: 32px;
              background-color: $white;
              box-shadow: none;
            }

            .right-sec {
              .dropdown-head {
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                color: $theme-black;
                margin-bottom: 3px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 240px;
              }

              .dropdown-des {
                font-size: 12px;
                text-transform: initial;
              }

              .shared-by {
                font-size: 13px;
                text-transform: capitalize;
              }
            }
          }

          .tab-left-right {
            .icon-next-arrow-grey {
              &::before {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.dashboard-left {
  .section-title {
    color: $white;
    text-shadow: 0.496057391166687px 0.599053680896759px 1.0625px
        rgba(0, 0, 0, 0.07),
      2.182652473449707px 2.635836124420166px 2.200000047683716px
        rgba(0, 0, 0, 0.11),
      5.357419967651367px 6.469779968261719px 4.387499809265137px
        rgba(0, 0, 0, 0.14),
      10.317994117736816px 12.46031665802002px 8.600000381469727px
        rgba(0, 0, 0, 0.17),
      17.362009048461914px 20.96687889099121px 15.8125px rgba(0, 0, 0, 0.21),
      26.787099838256836px 32.348899841308594px 27px rgba(0, 0, 0, 0.28);
    font-weight: 500;
    line-height: normal;
    padding-bottom: 35px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-transform: capitalize;
  }
}

.dashboard-right {
  .workbench-chart-main {
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(25px);

    .workbench-board-body {
      padding: 0px 0px 0px;

      .tooltip-container {
        .tooltip-text {
          width: max-content;
        }
      }
    }
  }
}

.workbench-board-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 20px;
  position: absolute;
  right: 68px;
  top: 71px;
  z-index: 5;

  .breadcrumb-text-board {
    background-color: white;
    padding: 5px 12px;
    border-radius: 30px;
    .bread-crumb {
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
    }
    .active {
      font-weight: bold;
    }
  }

  .workbench-options {
    display: flex;
  }

  .btn-primary {
    filter: none;
  }

  .create-modal-dropdown {
    margin-right: 10px;

    .dropdown-toggle {
      color: #838383 !important;
      font-size: 12px;
      font-weight: 400;
      background-color: #dbdedf;
      width: 97px;
      height: 30px;
      padding: 8px 20px 8px 14px;

      &:hover {
        background-color: #dbdedf;
      }

      &::after {
        vertical-align: 0.15em;
      }
    }

    .dropdown-menu {
      width: 170px;
      right: 0 !important;
      left: inherit !important;

      .dropdown-item {
        color: $text-danger;
        font-size: 14px;
        font-weight: 400;

        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  .setting-btn {
    padding: 0 8px 0 0;
    height: auto;
    position: relative;

    &:last-child {
      padding-right: 0;
    }
  }
}

.undo-redo {
  position: absolute;
  top: 16px;
  right: 20px;
  z-index: 9;
  display: flex;

  .tooltip-container {
    &.undo {
      margin-right: 8px;
    }

    &.redo {
      .circle-logo-wrapper {
        .icon-undo {
          transform: rotateY(180deg);
        }
      }
    }
  }
}

.workbench-board-wrapper {
  height: 536px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  min-height: 255px;
  max-height: 701px;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  .tooltip-container {
    .tooltip-text {
      width: max-content;
    }
  }

  @include min-height-860 {
    height: 641px;
  }

  @include max-height-768 {
    height: 490px;
  }

  .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
    padding: 0px;
    z-index: 2;
  }

  .hover-box-wrap {
    display: block;
    position: absolute;
    z-index: 10;

    &.comment-box-wrap {
      background-color: rgba(255, 255, 255, 1);
      backdrop-filter: none;
      padding: 16px 6px 23px 20px;
      max-height: 250px;
      height: auto;

      @include max-height-650 {
        padding: 16px 6px 20px 20px;
        max-height: 246px;
      }

      .hover-box {
        max-height: 220px;
        background-color: rgba(255, 255, 255, 1);
        backdrop-filter: none;
        padding-right: 12px;

        .form-group {
          margin-bottom: 0;

          .form-control {
            height: 44px;
            border-color: #bcbcbc;
            padding: 10px 45px 10px 10px;
          }

          .comment-send-btn {
            min-width: 26px;
            height: 26px;
            font-size: 13px;
            padding: 0;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    &.default-text-comment-wrap {
      max-height: initial;
      height: auto;
    }
  }

  .workbench-board-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;

    @include xx-large-devices {
      max-width: 50%;
    }
  }
}

.workbench-board-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .footer-board-left {
    color: rgba(0, 0, 0, 0.22);
    font-size: 12px;
    font-weight: 700;
  }

  .workbench-board-header {
    display: flex;
    margin-bottom: 20px;
    right: 0;
    top: inherit;

    .circle-logo-wrapper {
      &.delete-btn {
        background-color: $bg-danger;

        &.active {
          background-color: $white;
        }
      }
    }
  }
}

.workbench-chart-header {
  background-color: #183957;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .work-board-text {
    color: $white;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    padding-left: 17px;
    padding-top: 2px;

    .bread-crumb {
      opacity: 0.5;
      cursor: pointer;

      &.active {
        opacity: 1;
      }
    }
  }

  .dsider-scenario-dropdown {
    .dropdown-toggle {
      &.btn {
        height: 100%;
        background-color: #1a4e7d;
        border-radius: 0;

        &:hover {
          background-color: #1a4e7d;
        }
      }
    }
  }

  .primary-btn {
    &.no-radius {
      background-color: $checkbox-success;
      min-width: 165px;
      height: 55px;
      font-size: 16px;
      filter: none;

      .icon-simulate-icon {
        padding-right: 11px;

        &::before {
          font-size: 22px;
        }
      }

      .simulate-icon-workbench {
        padding-right: 11px;
      }
    }

    &:disabled {
      .simulation-lock-img {
        display: inline-flex;
      }

      .icon-simulate-icon {
        display: none;
      }
    }
  }
  &.search-param {
    .custom-search {
      padding-left: 30px;
    }
    .search-param-text {
      display: flex;
      align-items: center;
      position: relative;
      &:hover {
        .tooltip-text {
          visibility: visible;
          opacity: 1;
          margin-left: 30px;
          margin-top: 5px;
        }
        .tooltip-hidden {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
    .approval-search-wrap {
      .search-form-group {
        background-color: transparent;
        margin-bottom: 5px;
      }
      .cross-logo {
        position: absolute;
        right: -35px;
        top: 5px;
      }
      .form-control {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        margin-left: 10px;
        height: 25px;
        padding: 0px;
        color: #fff;
      }
      .comment-box-wrap {
        top: 42px;
        left: 30px;
        width: 240px !important;
        padding: 0px !important;
        .dropdown-des {
          color: #0172B6 !important;
          font-weight: 600 !important;
          font-size: 16px !important;
        }
        .hover-box {
          padding-right: 0px;
        }
        .tab-left-part {
          padding: 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          &:last-child {
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

.drag-hint-wrapper {
  display: flex;
  align-items: center;
  padding: 20px 0 40px;

  .icon-dragdrop-icon {
    margin-right: 10px;

    &::before {
      color: rgba(255, 255, 255, 0.7);
      font-size: 18px;
    }
  }

  .drag-hint-text {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
}

// Object Parameters
.component-name {
  width: 75%;
  display: inline-block;

  &:hover {
    .edit-icon-wrap {
      visibility: visible;
    }
  }
}

.edit-component-name {
  margin-bottom: 16px;

  .component-input {
    border: 0px;
    border-bottom: 1px solid #000;
    width: 80%;
    height: 24px;
    font-size: 20px;
    font-weight: 600;
    padding: 0px;
  }
}

.operational-main-wrapper {
  .spinner-wrap {
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .object-parameter-form {
    width: 100%;

    .setting-form-content {
      margin-top: 0;
    }
  }
}

.workbench-board-empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 90px;

  .workbench-empty-img {
    width: 189px;
    margin-bottom: 24px;
  }

  .workbench-empty-wrapper {
    text-align: center;
    max-width: 570px;

    .workbench-empty-head {
      color: #8c8c8c;
      font-size: 20px;
      font-weight: 400;
    }

    .workbench-empty-des {
      color: #8c8c8c;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.chart-btn-wrapper {
  display: flex;

  .save-btn {
    background-color: #1a4e7d !important;
    padding: 13px 34px !important;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: #1a4e7d !important;
    }
  }
}

.approval-chart-btn-wrap {
  display: flex;
}

// simulation chart
.simulation-sec-main-wrap {
  &.ev-simulation-sec-main-wrap {
    padding-bottom: 30px;

    .simulation-chart-wrapper {
      .chart-right {
        border-top-right-radius: 21px !important;
      }
    }
  }
}

.simulation-chart-wrapper {
  scroll-margin: 70px;
  width: 100%;
  min-height: 600px;
  margin-top: 50px;
  border-radius: 21px;
  display: flex;
  background-color: rgba(235, 236, 236, 1);
  filter: none;
  backdrop-filter: blur(18.18181800842285px);

  @include max-height-768 {
    min-height: 560px;
  }

  &.mitigation-chart {
    flex-direction: column;
    overflow: hidden;
    max-width: 99%;
    background-color: transparent;

    .mitigation-wrap {
      display: flex;
      width: 100%;
      z-index: 9;

      .co2-wrap {
        width: 25%;
        background-color: rgba(16, 121, 148, 0.9);
        backdrop-filter: blur(29.06818199157715px);
        padding: 35px;

        @include max-height-768 {
          padding: 30px 25px 22px 25px;
        }

        .section-logo {
          @include max-height-768 {
            img {
              width: 56px;
            }
          }
        }

        &.mitigation-double-col {
          .co2-inner-wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .mitigation-label {
              margin-bottom: 0;
              max-width: 140px;
              width: 100%;
            }

            .mitigation-value {
              margin-left: 28px;
            }
          }
        }

        &.scope-chart-wrap {
          .section-head {
            margin-bottom: 20px;
          }

          .co2-inner-wrap {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 24px;

            .mitigation-label {
              max-width: 100%;
              margin-bottom: 4px;
            }

            .mitigation-value {
              margin-left: 0;
            }

            &.total-scope-wrap {
              margin-top: 30px;
              border-top: 1px solid rgba(255, 255, 255, 0.49);
              padding-top: 30px;

              .mitigation-label {
                font-weight: 700;
              }

              .mitigation-value {
                font-weight: 600;
                font-size: 18px;
              }

              .yearly-emissions {
                margin-top: 24px;
              }

              &.hydrogen-total {
                margin-top: 25px;
                padding-top: 25px;
              }
            }
          }

          &.hydrogen-emission {
            .co2-inner-wrap {
              margin-bottom: 18px;
            }
          }
        }
      }

      .section-logo {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 60px;

        @include max-height-768 {
          margin-bottom: 50px;
        }

        &.nvp-sec-logo {
          margin-bottom: 0;

          img {
            @include max-height-768 {
              width: 33px;
            }
          }
        }
      }

      .credits-value {
        color: $white;
        font-size: 32px;
        font-weight: 300;
        margin-bottom: 4px;

        &.npv-value {
          font-size: 18px;

          @include max-height-768 {
            font-size: 12px;
          }
        }

        &.demand-head {
          color: $white;
          max-width: 190px;
          min-width: 190px;
          margin-bottom: 0;
          padding-right: 70px;
          font-size: 16px;
          font-weight: 700;
        }
      }

      .credits-des {
        color: $white;
        font-size: 18px;
        font-weight: 300;
        padding-bottom: 20px;
        display: block;

        @include max-height-768 {
          font-size: 14px;
        }

        &.npv-des {
          font-size: 32px;
          padding-bottom: 0;

          @include max-height-768 {
            font-size: 16px;
          }
        }
      }

      .suppy-credit-wrap {
        &.npv-credit-wrap {
          &.ev-demand-total-inner {
            min-width: 180px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;

            .demand-title {
              color: $white;
              font-size: 14px;
              font-weight: 300;
              margin-bottom: 5px;
            }

            .demand-value {
              color: $white;
              font-size: 22px;
              font-weight: 300;
            }
          }
        }
      }

      .mitigation-right-wrap {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        .supply-left {
          width: 30%;
          background-color: rgba(64, 108, 186, 0.9);
          backdrop-filter: blur(29.06818199157715px);
        }

        .supply-right {
          width: 70%;
          display: flex;
          background-color: rgba(16, 62, 141, 0.9);
          backdrop-filter: blur(29.06818199157715px);

          &.power-supply-right {
            width: 100%;
            border-top-right-radius: 21px;
            overflow: hidden;
          }

          .tax-credits {
            width: 50%;
            position: relative;
            background-color: rgba(16, 62, 141, 0.8);
            backdrop-filter: blur(29.06818199157715px);

            @include max-height-768 {
              padding: 30px 25px 2px 25px;
            }

            &::after {
              content: "";
              width: 1px;
              position: absolute;
              right: 0;
              top: 35px;
              bottom: 35px;
              background-color: rgba(255, 255, 255, 0.15);

              @include max-height-768 {
                top: 30px;
                bottom: 30px;
              }
            }

            .section-logo {
              padding-right: 10px;
            }

            &.ev-demand-total {
              display: flex;
              align-items: center;
            }
          }

          .carbon-tax {
            width: 50%;
            background-color: rgba(16, 62, 141, 0.8);
            backdrop-filter: blur(29.06818199157715px);

            @include max-height-768 {
              padding: 30px 25px 2px 25px;
            }
          }
        }

        .supply-nvp {
          width: 100%;
          display: flex;

          .nvp-left {
            width: 50%;
            background-color: rgba(6, 116, 171, 0.9);
            backdrop-filter: blur(29.06818199157715px);

            @include max-height-768 {
              padding: 22px 25px;
            }
          }

          .nvp-right {
            width: 50%;
            background-color: rgba(24, 140, 199, 0.9);
            backdrop-filter: blur(29.06818199157715px);

            @include max-height-768 {
              padding: 22px 25px;
            }
          }
        }
      }

      .section-head {
        color: $white;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 25px;

        @include max-height-768 {
          font-size: 14px;
          margin-bottom: 14px;
        }
      }

      .co2-inner-wrap {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .mitigation-value {
          color: $white;
          font-size: 18px;
          font-weight: 300;
          margin-bottom: 0;

          @include max-height-768 {
            font-size: 14px;
          }
        }
      }
    }

    .cost-wrap {
      width: 100%;
      display: flex;
      background-color: rgba(23, 64, 113, 0.9);
      backdrop-filter: blur(29.06818199157715px);
      padding: 30px 35px;

      .cost-img-sec {
        width: 50%;
        display: flex;

        @include computer-max-1440 {
          width: 40%;
        }

        .cost-img-wrap {
          width: 40%;
          display: flex;
          justify-content: center;
          align-items: center;

          .cost-img-inner-wrap {
            position: relative;
            height: fit-content;
            width: fit-content;

            @include max-height-768 {
              img {
                width: 135px;
              }
            }
          }
        }

        .cost-text-wrap {
          width: 58%;
          position: relative;

          @include computer-max-1440 {
            width: 60%;
            padding-left: 20px;
          }

          &::after {
            content: "";
            width: 1px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }

      .cost-value-one {
        width: 25%;
        position: relative;
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding-left: 30px;

        @include computer-max-1440 {
          width: 30%;
        }

        &::after {
          content: "";
          width: 1px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      .cost-value-two {
        width: 25%;
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding-left: 30px;

        @include computer-max-1440 {
          width: 30%;
        }
      }
    }
  }

  &.geothermal-table {
    .mitigation-wrap {
      .geo-left-wrap {
        width: calc(50% + 1px);
        display: flex;

        .co2-wrap {
          width: 45%;
          background-color: rgba(16, 121, 148, 0.9);
          -webkit-backdrop-filter: blur(29.0681819916px);
          backdrop-filter: blur(29.0681819916px);
          padding: 35px;

          &.supply-left {
            background-color: rgba(64, 108, 186, 0.8);
            backdrop-filter: blur(40px);
            width: 55%;
          }
        }
      }

      .mitigation-right-wrap {
        width: 50%;

        .supply-right {
          width: 100%;
          flex-direction: column;
          background-color: rgba(16, 62, 141, 0.8);
          backdrop-filter: blur(40px);
          padding: 35px 35px 10px;
          align-items: flex-start;

          .geo-dropdown-btn {
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 25px;

            @include max-height-768 {
              font-size: 14px;
              margin-bottom: 14px;
            }
          }

          .reservoir-row {
            .reservoir-wrap {
              margin-bottom: 20px;

              &:last-child {
                margin-bottom: 0;
              }

              .credits-des {
                font-size: 14px;
                margin-bottom: 5px;
                padding-bottom: 0;

                @include max-height-768 {
                  font-size: 12px;
                }
              }

              .credits-value {
                font-size: 18px;

                @include max-height-768 {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }

    .supply-nvp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 119px;

      .co2-wrap {
        width: calc(50% + 1px);
        display: flex;
        justify-content: space-between;
        height: 100%;

        .vir-sec {
          width: 45%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          padding: 40px 35px 35px;

          &::after {
            content: "";
            width: 1px;
            position: absolute;
            right: 0;
            background-color: rgba(255, 255, 255, 0.2);
            top: 20px;
            bottom: 20px;
          }

          .vir-des {
            font-size: 32px;

            @include max-height-768 {
              font-size: 22px;
            }
          }

          &.moic-sec {
            width: 55%;

            &::after {
              display: none;
            }
          }
        }

        &.nvp-right {
          .vir-sec {
            width: 50%;

            &.moic-sec {
              width: 60%;
              position: relative;
            }
          }
        }

        &.nvp-left {
          background-color: rgba(2, 115, 180, 0.8);
        }
      }

      .nvp-right {
        width: 50%;
        display: flex;
        justify-content: space-between;
        background-color: rgba(83, 175, 142, 0.8);

        .vir-sec {
          padding: 35px;

          .geo-dropdown-btn {
            color: $white;
          }
        }
      }

      &.geo-npv {
        height: 100%;
        min-height: 119px;

        .nvp-left {
          width: 100%;

          .tax-credits {
            width: 33.33%;
            backdrop-filter: blur(40px);
            padding: 35px;
            position: relative;

            .suppy-credit-wrap {
              margin-top: -20px;
            }

            .section-logo {
              position: static;
              display: flex;
              justify-content: flex-end;

              &.visibility-hidden {
                visibility: hidden;
              }
            }

            &.geo-net-avg {
              background-color: #1f7d95;
            }

            &.geo-npv {
              background-color: rgba(6, 116, 171, 0.7);
            }

            &.geo-irr {
              background-color: rgba(24, 140, 199, 0.7);
            }
          }
        }
      }
    }

    .cost-wrap {
      padding: 0;

      &::after {
        content: unset;
      }

      .cost-img-sec {
        width: 66.66%;
        padding: 30px 35px;
        position: relative;

        &::after {
          content: "";
          width: 1px;
          position: absolute;
          right: 0;
          background-color: rgba(255, 255, 255, 0.2);
          top: 20px;
          bottom: 20px;
        }

        .cost-text-wrap {
          &::after {
            content: unset;
          }

          .cost-text-inner-wrap {
            .cost-text-head {
              @include max-height-768 {
                font-size: 22px;
              }
            }
          }
        }
      }

      .cost-value-two {
        padding: 30px 35px;

        .cost-value-wrap {
          flex-direction: column;
          align-items: flex-start;

          .mitigation-label {
            min-width: auto;
          }

          .lcoe-value {
            margin-left: 0;
            font-size: 22px;
          }
        }
      }

      .geo-dropdown-btn {
        color: $white;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        /* 133.333% */
        @include max-height-768 {
          font-size: 12px;
        }

        &:after {
          position: static;
          margin-bottom: -1px;
        }
      }
    }
  }

  &.ev-scratch-simulation-chart-wrap {
    margin-top: 20px;

    .chart-right-inner {
      .slider-wrap {
        .simulation-form-wrap {
          .simulation-form {
            .simulation-inner-wrap {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .ev-simulation-map-left {
      .comment-msg-wrap {
        position: absolute;
        top: 15px;
        right: 59px;
        height: 50px;
        z-index: 2;

        .tooltip-container {
          .tooltip-text {
            width: max-content;
          }
        }
      }

      .place-search-box {
        position: absolute;
        top: 12px;
        right: 96px;
        z-index: 1;
        min-height: auto;
        height: auto !important;

        input {
          padding: 10px 10px 10px 35px;
          border-radius: 5px;
          border: 1px solid #ccc;
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          min-width: 240px;
          height: 35px;
          font-family: "Urbanist", sans-serif !important;

          &:focus {
            outline: none;
          }
        }

        .search-logo {
          position: absolute;
          left: 14px;
          top: 7px;
          width: 20px;
        }
      }

      > div {
        min-height: 690px;

        @include max-height-650 {
          min-height: 670px;
        }
      }
    }
  }

  &.ev-simulation-chart-wrap {
    margin-top: 20px;

    .chart-right-inner {
      .slider-wrap {
        .simulation-form-wrap {
          .simulation-form {
            .simulation-inner-wrap {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .simulation-map-left {
      .comment-msg-wrap {
        position: absolute;
        top: 15px;
        right: 59px;
        height: auto !important;
        z-index: 2;

        .tooltip-container {
          .tooltip-text {
            width: max-content;
          }
        }
      }

      > div {
        min-height: 690px;

        @include max-height-650 {
          min-height: 670px;
        }
      }
    }
  }

  &.ev-summary-table {
    min-height: 550px;

    .mitigation-wrap {
      .co2-wrap {
        &.point-int-wrap {
          padding: 0;
          background-color: transparent;
          backdrop-filter: none;
        }

        .ev-co2-wrap {
          background-color: rgba(16, 121, 148, 0.89);
          backdrop-filter: blur(29.0681819916px);
          padding: 33px 30px 30px;
          height: calc(100% - 112px);
        }

        .zip-code-col {
          background-color: rgba(64, 108, 186, 0.8);
          backdrop-filter: blur(40px);
          padding: 33px 30px 30px;
          min-height: 112px;

          .section-head {
            margin-bottom: 5px;
          }
        }

        .location-code-col {
          background-color: rgba(64, 108, 186, 0.8);
          backdrop-filter: blur(40px);
          padding: 33px 30px 30px;
          min-height: 130px;

          .section-head {
            margin-bottom: 5px;
          }
        }

        .section-head {
          font-size: 14px;
        }

        .ev-scratch-co2-wrap {
          background-color: rgba(16, 121, 148, 0.89);
          backdrop-filter: blur(29.0681819916px);
          padding: 33px 30px 30px;
          height: calc(100% - 130px);
        }

        .location-code-container {
          display: flex;
        }

        .suppy-credit-main-wrap {
          display: flex;
          justify-content: space-between;

          .suppy-credit-wrap {
            min-width: 200px;

            + .suppy-credit-wrap {
              .tooltip-container {
                width: 72%;
              }
            }
          }
        }

        .ev-section-head {
          margin-top: 56px;
          margin-bottom: 20px;
        }

        .co2-inner-wrap {
          .mitigation-value {
            font-size: 22px;
          }

          .mitigation-label {
            font-weight: 300;
            margin-bottom: 2px;
          }

          .mitigation-label-latitude {
            width: 170px;
          }
        }
      }

      .credits-value {
        font-size: 22px;
        margin-bottom: 5px;
      }

      .credits-des {
        font-size: 14px;
        padding-bottom: 0;
      }

      .mitigation-right-wrap {
        .supply-right {
          width: 100%;
          z-index: 10;

          .tax-credits {
            padding: 57px 30px 30px;

            &::after {
              top: 27px;
              bottom: 27px;
            }
          }

          .carbon-tax {
            padding: 57px 30px 30px;

            .section-logo {
              position: absolute;
              top: 30px;
              right: 30px;
            }
          }
        }

        .supply-nvp {
          flex-wrap: wrap;
          z-index: 9;

          .tax-credits {
            &.ev-demand-total {
              display: flex;
              align-items: center;
            }
          }

          .co2-wrap {
            width: 100%;
            padding: 30px 30px 20px;
            z-index: 9;
          }

          .nvp-right {
            &.co2-wrap {
              z-index: 8;
            }
          }
        }
      }
    }

    .cost-wrap {
      background-color: transparent;
      padding: 0;

      .tooltip-container {
        .tooltip-text {
          top: initial;
          bottom: 70%;
        }
      }

      .cost-img-sec {
        width: 70%;
        position: relative;
        background-color: rgba(23, 64, 113, 0.9);
        padding: 51px 30px 30px;

        .cost-img-wrap {
          position: absolute;
          right: 77px;
          width: auto;
        }

        .cost-text-wrap {
          &::after {
            background-color: transparent;
          }
        }
      }

      .cost-value-one {
        width: 30%;
        background-color: rgba(44, 81, 126, 0.9);
        backdrop-filter: blur(29.06818199157715px);
        position: relative;
        padding: 88px 30px 30px;

        &::after {
          background-color: transparent;
        }

        .section-logo {
          position: absolute;
          right: 30px;
          top: 30px;
        }
      }

      .cost-text-inner-wrap {
        margin-bottom: 0;
        min-width: 170px;

        .mitigation-label {
          font-weight: 300;
        }

        .cost-text-head {
          font-size: 22px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.mitigation-label {
  color: $white;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  display: block;

  @include max-height-768 {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.cost-value-head {
  color: $white;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  display: block;

  @include max-height-768 {
    font-size: 12px;
  }
}

.lcoe-value {
  color: $white;
  font-size: 22px;
  font-weight: 300;
  display: block;
  margin-bottom: 0;

  @include max-height-768 {
    font-size: 14px;
  }
}

.co2-inner-wrap {
  &.cost-value-wrap {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include max-height-768 {
      .lcoe-value {
        margin-left: 28px;
      }
    }

    .mitigation-label {
      margin-bottom: 0;
      min-width: 130px;
      width: 100%;

      @include max-height-768 {
        font-size: 14px;
        min-width: 98px;
        width: 100%;
      }
    }
  }
}

.cost-text-inner-wrap {
  margin-bottom: 34px;

  @include max-height-768 {
    margin-bottom: 20px;
  }
  .geo-dropdown-btn {
    color: $white;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    @include max-height-768 {
      font-size: 12px;
    }

    &:hover {
      color: $white;
    }
    &::after {
      position: static;
      margin-bottom: -1px;
    }
  }
}

.cost-text-head {
  color: $white;
  font-size: 32px;
  font-weight: 300;

  @include max-height-768 {
    font-size: 14px;
  }
}

.cost-img-wrap {
  h3 {
    color: $white;
    text-shadow: 0.360487163066864px 0.43533504009246826px 0.7721235752105713px
        rgba(0, 0, 0, 0.07),
      1.5861434936523438px 1.9154740571975708px 1.5987499952316284px
        rgba(0, 0, 0, 0.11),
      3.893261432647705px 4.701618671417236px 3.188416004180908px
        rgba(0, 0, 0, 0.14),
      7.498133182525635px 9.05496883392334px 6.249659538269043px
        rgba(0, 0, 0, 0.17),
      12.617051124572754px 15.236725807189941px 11.491015434265137px
        rgba(0, 0, 0, 0.21),
      19.466306686401367px 23.508092880249023px 19.621023178100586px
        rgba(0, 0, 0, 0.28);
    font-size: 35px;
    font-weight: 500;
    position: absolute;
    bottom: 23px;
    right: 48px;
  }
}

.chart-left {
  width: calc(100% - 313px);
  height: 100%;
  border-top-left-radius: 21px;
  border-bottom-left-radius: 21px;
  padding: 45px;
  min-height: 690px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @include max-height-690 {
    min-height: 600px;
  }

  .checkbox-wrapper {
    margin-bottom: 35px;
    flex-wrap: wrap;

    .form-check {
      margin-bottom: 20px;

      .form-check-label {
        font-weight: 600;
      }

      .form-check-input[type="radio"] {
        margin-right: 5px;
      }
    }
  }
}

.chart-right {
  width: 313px;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  padding: 30px 30px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(18.18181800842285px);
  display: flex;
  align-items: stretch;

  .chart-right-inner {
    width: 100%;

    &.ev-chart-right {
      .slider-wrap {
        .simulation-form-wrap {
          .simulation-form {
            .simulation-inner-wrap {
              overflow-y: initial;
            }
          }
        }
      }
    }
  }
}

.gm-style .gm-style-iw-c {
  width: 207px;
  overflow: visible;
  border-radius: 21px;
  padding: 20px;
  display: flex;
  background-color: transparent;
  box-shadow: none;
  top: -25px;

  .gm-style-iw-d {
    width: 167px !important;
    overflow: visible !important;
  }

  .gm-ui-hover-effect {
    top: 20px !important;
    right: 24px !important;

    span {
      width: 20px !important;
      height: 20px !important;
      background-color: #000 !important;
    }

    &.default-close-btn {
      top: 5px !important;
      right: 8px !important;
    }
  }
}

.gm-style .gm-style-iw-tc::after {
  background: transparent;
}

.map-tooltip-wrap {
  background-color: #4394c2 !important;
  margin: -20px;
  height: auto;
  padding: 20px;
  border-radius: 21px;
  position: relative;

  &::after {
    content: none;
  }

  &.proposed-station-wrap {
    background-color: #64a891 !important;

    &::after {
      content: none;
    }
  }

  &.poi-station-wrap {
    background-color: #7b7b7b !important;

    &::after {
      border-top: 20px solid #7b7b7b;
    }
  }

  &.aadt-station-wrap {
    background-color: #e36f49 !important;

    &::after {
      border-top: 20px solid #e36f49;
    }
  }

  .map-tooltip-logo-wrap {
    margin-bottom: 16px;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $white;
  }

  .map-tooltip-title {
    color: $white;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .map-tooltip-des {
    color: $white;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 15px;
    display: block;
    font-family: "Urbanist", sans-serif;
  }

  .map-traffic-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;

    .map-traffic-des {
      color: $white;
      font-size: 12px;
      font-weight: 400;
    }

    .map-traffic-revenue {
      color: $white;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.graph-value-kw {
  color: $theme-success;
  font-size: 20px;
  font-weight: 400;

  &.empty {
    color: $theme-black;
  }
}

.slider-wrap {
  .slider-head {
    color: $theme-black;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;

    &.graph-title-kw {
      margin-bottom: 8px;
    }
  }

  .slider-label-wrap {
    display: flex;
    justify-content: space-between;

    .form-label {
      color: $theme-black;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

.chart-title {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 27px;
}

.object-form-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-flow: row wrap;
  
  .row {
    width: 100%;
  }

  .object-left-col {
    width: 50%;
    padding-right: 12px;
  }

  .object-right-col {
    width: 50%;
    padding-left: 12px;
  }

  .custom-select-main {
    &.capital-cost-select {
      position: absolute;
      right: 0;
      top: 25px;
      cursor: default;

      .custom-select-wrp {
        .select__control {
          height: 40px;
          width: 120px;
          padding: 10px 40px 10px 0px;
          border: 0;
          text-align: right;
        }

        .select__menu {
          width: 130px;
          right: 0;
        }
      }
    }
  }

  .turbine-arrow-btn {
    height: 38px;
    position: absolute;
    right: 20px;
    top: 30px;

    &::after {
      border-top: 0.45em solid;
      color: #5a5b5e !important;
      margin-left: 0.255em;
      content: "";
      border-right: 0.3em solid transparent;
      border-left: 0.3em solid transparent;
    }
  }
}

.turbine-height-form,
.scheduling-form {
  .custom-select-main {
    position: relative;

    &.capital-cost-select {
      position: absolute;
      right: 0;
      top: 25px;

      .custom-select-wrp {
        .select__control {
          height: 54px;
          width: 190px;
          padding: 10px 40px 10px 0px;
          border: 0;
          text-align: right;

          .select__indicators .select__dropdown-indicator::after {
            top: 24px;
          }
        }

        .select__menu {
          width: 130px;
          right: 0;
        }
      }
    }
  }

  .error-field {
    .select__control {
      border-color: $text-error !important;
    }
  }
}

.chart-right-inner {
  .slider-wrap {
    .simulation-form-wrap {
      .simulation-form {
        .simulation-inner {
          margin-bottom: 30px;

          .tooltip-container {
            width: 100%;

            .tooltip-text {
              width: 100%;
            }
          }
        }

        .simulation-inner-wrap {
          max-height: 610px;
          overflow-y: auto;
          margin-right: -25px;
          padding-right: 25px;
          margin-bottom: 20px;

          @include max-height-700 {
            max-height: 590px;
          }

          .simulation-inner {
            margin-bottom: 20px;

            .form-group {
              margin-bottom: 20px;

              .form-label {
                font-size: 12px;
              }

              input {
                height: 30px;
                padding: 8px 15px;
                font-weight: 400;
                font-size: 12px;
              }

              .component-property {
                position: relative;

                .form-label {
                  margin-bottom: 5px;
                  margin-top: 15px;
                  font-size: 10px !important;
                }

                .custom-select-main {
                  &.capital-cost-select {
                    position: absolute;
                    right: 0;
                    top: 21px;

                    .custom-select-wrp {
                      .select__menu {
                        width: 130px;
                        right: 0;
                      }

                      .select__control {
                        height: 40px;
                        width: 190px;
                        padding: 5px 28px 10px 0px;
                        border: 0;
                        text-align: right;

                        .select__single-value {
                          font-size: 12px;
                          margin-top: 3px;
                        }

                        .select__dropdown-indicator::after {
                          right: 10px !important;
                        }
                      }
                    }
                  }
                }

                &.multiple-properties {
                  .form-label {
                    margin-top: 15px;
                  }

                  .capital-cost-select {
                    top: 19px;
                  }
                }
                &.three-dot-form-wrap {
                  .three-dot-wrap {
                    margin-top: 15px;
                    margin-bottom: 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    z-index: 1;

                    .form-label {
                      margin: 0;
                    }
                    .btn {
                      &.btn-no-outline {
                        width: 20px;
                        height: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .monte-carlo-link {
        font-size: 16px;
        color: #4387b0;
        font-weight: 700;
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        margin-top: 25px;
        cursor: pointer;
      }

      &.power-tab-right {
        max-height: 480px;
        overflow-y: auto;
        margin-right: -25px;
        padding-right: 25px;
      }
    }
  }
}

.slider-inner-sec {
  &.divider-border {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  &.divider-border-ev-scratch {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  // &.charging-stations-inner{
  //   max-height: 281px;
  //   overflow-y: auto;
  // }
}

.location-des {
  color: $text-error;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
}

.current-stations {
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  .location-logo {
    margin-right: 12px;
  }

  .location-logo-des {
    font-size: 14px;
    font-weight: 500;
  }
}

.ev-station-status {
  margin-top: 28px;

  @include computer-max-1440 {
    margin-top: 20px;
  }

  &:last-child {
    .ev-station-des {
      margin-bottom: 0;
    }
  }

  .tooltip-container {
    width: 100%;

    .tooltip-text {
      width: 100%;
    }
  }

  .ev-station-value {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .ev-station-des {
    font-size: 14px;
    font-weight: 500;
  }
}

.highcharts-background {
  fill: transparent !important;
}

.power-graph-slider {
  display: flex;
  justify-content: flex-end;

  img {
    width: 40%;
    margin-top: 30px;
  }
}

.comment-small-wrap {
  width: 44px;
  height: 44px;
  background: $white;
  position: absolute;
  top: -22px;
  right: -60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid $white;
    bottom: -5px;
    left: -7px;
    transform: rotate(35deg);
  }
}

.point-int-img {
  position: absolute;
  top: 40px;
  right: 32px;
}

.demographics-left-wrap {
  display: flex;
}

.demographics-title {
  color: $white;
  text-shadow: 0.360487163066864px 0.43533504009246826px 0.7721235752105713px
      rgba(0, 0, 0, 0.07),
    1.5861434936523438px 1.9154740571975708px 1.5987499952316284px
      rgba(0, 0, 0, 0.11),
    3.893261432647705px 4.701618671417236px 3.188416004180908px
      rgba(0, 0, 0, 0.14),
    7.498133182525635px 9.05496883392334px 6.249659538269043px
      rgba(0, 0, 0, 0.17),
    12.617051124572754px 15.236725807189941px 11.491015434265137px
      rgba(0, 0, 0, 0.21),
    19.466306686401367px 23.508092880249023px 19.621023178100586px
      rgba(0, 0, 0, 0.28);
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 20px;
}

.sstimated-head {
  color: $white;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  max-width: 160px;
}

.sstimated-value {
  color: $white;
  font-size: 22px;
  font-weight: 300;
}

.simulate-map-btn {
  margin: 0 auto;
}

.poi-info-window {
  background-color: $white;
  border-radius: 11px;
  position: relative;
  padding: 30px 25px 25px;

  .div {
    background-color: transparent;
  }

  .full-width {
    width: auto;
  }
}

.poi-info-window a {
  background-color: transparent !important;
}

.ev-card-logo {
  position: absolute;
  top: 14px;
  left: 20px;
}

.comment-box-wrap {
  position: absolute;
  top: 30px;
  left: 20%;
  z-index: 5;

  .hover-box {
    max-height: 220px;
    overflow-y: auto;

    .profile-icon-wrap {
      position: relative;
    }
  }

  .form-group {
    margin-bottom: 0;

    .form-control {
      height: 44px;
      padding-right: 45px;
      padding-left: 10px;
    }

    .comment-send-btn {
      min-width: 26px;
      height: 26px;
      font-size: 13px;
      padding: 0;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.ev-comment-box {
  z-index: 1099 !important;

  .card-dropdown {
    &.comment-dropdown-wrap {
      top: 1px;
    }
  }
}

.comment-msg-wrap + div {
  height: 700px !important;

  @include max-height-650 {
    height: 670px !important;
  }
}

.ev-bp-table-sec {
  margin-top: 0px;
}

.bp-th {
  margin-bottom: 25px;
  color: $white;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
}

.td-inner-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 22px;

  .td-label {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;

    .td-label-head {
      display: block;
      font-weight: 700;
    }
  }

  .td-value {
    color: $white;
    text-align: right;
    font-size: 18px;
    font-weight: 300;
  }
}

.varticle-border {
  position: relative;
  padding-left: 36px;
  padding-right: 36px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.49);
    height: 100%;
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

.operational-empty-wrap {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 100px;

  .operational-empty-img {
    display: flex;
    justify-content: center;
  }

  .operational-empty-des {
    margin-bottom: 0;
    color: #a7a7a8;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
}

.map-data-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
}

.dsider-back-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  .ai-back-btn {
    margin-right: 10px;
  }

  .ev-graph-des {
    margin-bottom: 0;
    font-size: 14px;
  }
}

.simulation-lock-img {
  margin-right: 11px;
  display: none;
}

.comment-count-wrap {
  width: 20px;
  height: 16px;
  border-radius: 100px;
  border: 1px solid $white;
  background-color: $text-error;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9px;
  right: 0px;
}

.comment-count {
  color: $white;
  font-size: 12px;
  font-weight: 700;
}

.card-dropdown {
  &.comment-dropdown-wrap {
    position: absolute;
    right: 0;
    top: -1px;

    .three-dot-dropdown {
      .dropdown-menu {
        width: 133px !important;
        right: 0px !important;
        left: initial !important;
        padding: 20px;

        .dropdown-item-text {
          font-size: 13px;
          margin-bottom: 13px;
          color: #5a5b5e;
        }

        .dropdown-item {
          color: $theme-black;

          &:hover {
            font-weight: 600;
          }

          &.text-danger {
            color: $text-error !important;
          }
        }
      }
    }
  }
}

.edit-icon-wrap {
  margin-left: 10px !important;
  display: inline-block;
  visibility: hidden;

  .edit-icon {
    margin-bottom: 6px;
  }
}

// Object Layers
.objet-layer-main {
  .object-ul {
    padding: 0 35px;
    overflow-y: auto;
    max-height: 670px;
    margin-right: 2px;

    @include max-height-768 {
      max-height: 600px;
    }

    @include max-height-690 {
      max-height: 430px;
    }

    .tab-left-part {
      &.object-ul-inner {
        position: relative;
        z-index: 2;
        margin-bottom: 30px;

        &::before {
          content: "";
          width: 1px;
          height: calc(100% - 40px);
          background-color: #bdbdbd;
          position: absolute;
          top: 36px;
          left: 18px;
          z-index: -1;
        }
      }

      .dropdown-wrapper {
        align-items: center;
        border-bottom: 0;
        padding-bottom: 0;
        margin-top: 30px;
        margin-bottom: 0;

        .right-sec {
          .dropdown-head {
            margin-bottom: 0;
            font-weight: 700;
          }
        }
      }
    }
  }

  .object-li-wrap {
    margin-left: 50px;
    z-index: 2;
    position: relative;

    &::before {
      content: "";
      width: 1px;
      height: calc(100% - 40px);
      background-color: #bdbdbd;
      position: absolute;
      top: 36px;
      left: 18px;
      z-index: -1;
    }

    .object-li {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: -32px;
        top: 9px;
        width: 30px;
        height: 9px;
        border-bottom: 1px solid #bdbdbd;
        border-bottom-left-radius: 10px;
      }

      .dropdown-wrapper {
        margin-top: 20px;
      }

      &:last-child {
        .object-li-inner {
          .dropdown-wrapper {
            margin-bottom: 0;
          }
        }
      }

      .object-li-nested-wrap {
        .object-li-nested-inner {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: -32px;
            top: 9px;
            width: 30px;
            height: 9px;
            border-bottom: 1px solid #bdbdbd;
            border-bottom-left-radius: 10px;
          }
        }
      }

      .right-sec {
        .dropdown-head {
          font-weight: 400 !important;
        }
      }
    }

    .object-li-nested-wrap {
      margin-left: 50px;
    }
  }

  &.add-object-icon-main {
    .object-ul {
      max-height: 378px;
      margin: 0px 4px 0px 0;

      &.add-new-parameter-wrap {
        max-height: 398px;
        margin: 30px 4px 0px;

        .select-radio-grey {
          margin-bottom: 30px;
          position: relative;
        }

        .custom-select-wrp {
          .select__menu {
            .select__menu-list {
              max-height: 170px;

              .select__option {
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.place-search-box {
  position: absolute;
  top: 12px;
  right: 96px;
  z-index: 9999;
  min-height: auto;
  height: auto !important;

  input {
    padding: 10px 10px 10px 35px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    min-width: 240px;
    height: 35px;
    font-family: "Urbanist", sans-serif !important;

    &:focus {
      outline: none;
    }
  }

  .search-logo {
    position: absolute;
    left: 14px;
    top: 7px;
    width: 20px;
  }
}

.vir-head {
  color: $white;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;

  @include max-height-768 {
    font-size: 14px !important;
  }
}

.vir-des {
  color: $white;
  font-size: 32px;
  font-weight: 300;

  @include max-height-768 {
    font-size: 22px;
  }
}

// monto-carlo-chart
.monto-carlo-chart {
  .chart-title {
    position: relative;
    padding-left: 59px;

    .close-btn {
      top: 50%;
      right: inherit;
      left: 0;
      width: 45px;
      height: 45px;
      transform: translateY(-53%);
    }
  }

  .chart-right {
    .chart-right-inner {
      .custom-select-main {
        margin-bottom: 32px;
      }

      .slider-wrap-inner {
        margin-bottom: 38px;

        .calculate-btn {
          min-width: 63px;
          border-radius: 0;
          border-color: #5a5b5e;
          height: 30px;
          padding: 8px 8px;
          font-size: 12px;
          font-weight: 700;
        }

        .schedule-slider-progress-value-wrap {
          .star-progress-bar {
            width: 150px;
          }
        }
      }
    }
  }
}

.monte-form-group {
  margin-bottom: 32px;
  cursor: pointer;

  .monte-select-button {
    border: 1px solid #5a5b5e;
    height: 54px;
    background: transparent;
    border-radius: 0;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 20px;
    color: #5a5b5e;
    display: flex;
    align-items: center;
    max-height: 76px;

    .disable-placeholder {
      color: rgba(90, 91, 94, 0.2);
      font-size: 14px;
      font-weight: 500;
    }

    .monte-modal-value {
      color: $theme-black;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;

      .value-numbers {
        color: $theme-success;
      }
    }
  }

  .form-label {
    display: block;
  }
}

.power-graph-main-wrap {
  display: flex;

  .power-graph-wrap {
    padding: 0 0px;
    flex: 1;

    &.right-border {
      border-right: 1px solid rgba(90, 91, 94, 0.5);
      padding-right: 40px;
    }

    .highcharts-legend-box {
      fill: transparent;
      stroke: transparent;
    }
  }

  .highcharts-legend-item.highcharts-column-series text {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
}

.print-tb-wrap {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  margin-top: 18px;

  .btn-no-outline {
    color: $white;
    text-align: right;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;

    &:hover {
      color: $white;
    }

    &.print-btn {
      position: relative;
      padding-left: 32px;
      padding-right: 20px;

      &::before {
        content: "";
        background: url(../images/print-btn.svg) no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
      }
    }
  }
}

.monte-carlo-wrap {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 24px 24px;
  border: 5px solid rgb(16, 113, 172);

  h5 {
    font-size: 30px;
    font-weight: 700;
    line-height: 24px;
  }
}

.marker-label {
  margin-bottom: 60px !important;
  background: #1071ac;
  padding: 5px 7px;
  border-radius: 5px;
  text-align: center;
  width: auto;
}
.switch-year-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 51px;
  right: 30px;
  .ai-chat-switch-wrap .switch-btn-label {
    font-size: 12px;
    text-transform: uppercase;
  }
  &.ship-obj-toggle {
    position: initial;
    margin-top: 10px;
    .form-check.form-switch .form-check-input {
      background-size: 15px !important;
    }
  }
}
