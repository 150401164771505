$font-primary: 'Urbanist', sans-serif;
$font-secondary: 'Indie Flower', cursive;

// font weight variables
$fw-light: 300 !default;
$fw-regular: 400 !default;
$fw-medium: 500 !default;
$fw-semibold: 600 !default;
$fw-bold: 700 !default;

$theme-black: #5a5b5e;
$theme-green: #39cfa5;
$theme-pink: #fafafa;
$theme-orange: #e36f49;
$text-grey: #444444;
$text-error: #e54d4d;
$approved: #3FC63C;
$pending: #F7BD4E;
$text-danger: #FA7575;
$bg-danger: #fa7575;
$bg-error: #e54d4d;
$text-success: #16b487;
$theme-success: #0172b6;
$bg-grey: #f2f2f2;
$white: #fff;
$white-opacity-6: rgba(255, 255, 255, 0.6);
$white-light: #ffffff85;
$violet-color: #ded4fb;
$gray-ef: #efefef;
$primary-color: #4387b0;
$card-bg-1: rgba(64, 108, 186, 0.9);
$card-bg-2: rgba(6, 116, 171, 0.9);
$card-bg-3: rgba(16, 121, 148, 0.89);
$tost-success: #0172b6;
$checkbox-success: #0172b6;
$react-flow-border: #7A3BC9;
$cyan-color: #17DFEC;
$purple-color: #534FBE;
$light-green-color: #67DF7E;
$orange-color: #EC7345;
$light-sky-color: #7189B8;
$pink-color: #C76EF3;
$light-cyan-color: #6EDDCA;
$red-color: #E7594B;
$light-yellow-color: #E3AF49;
$dark-red-color: #AD3F1B;
$dark-purple-color: #51139E;


