// Min width
@mixin small-devices {
  @media (min-width: 576px) {
    @content;
  }
}
@mixin medium-devices {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin large-devices {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin x-large-devices {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin xx-large-devices {
  @media (min-width: 1441px) {
    @content;
  }
}

// Max width
@mixin computer-max {
  @media (max-width: 1439px) {
    @content;
  }
}

@mixin computer-max-1440 {
  @media (max-width: 1440px) {
    @content;
  }
}
@mixin computer-max-1400 {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin laptop-max-1366 {
  @media (max-width: 1366px) {
    @content;
  }
}
@mixin laptop-max {
  @media (max-width: 1365px) {
    @content;
  }
}
@mixin max-large-1280 {
  @media (max-width: 1280px) {
    @content;
  }
}
@mixin max-large-devices {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin tablet-max {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin mobile-max {
  @media (max-width: 575px) {
    @content;
  }
}

// Max Height
@mixin min-height-860 {
  @media (min-height: 860px) {
    @content;
  }
}
@mixin max-height-768 {
  @media (max-height: 768px) {
    @content;
  }
}
@mixin max-height-740 {
  @media (max-height: 740px) {
    @content;
  }
}

@mixin max-height-730 {
  @media (max-height: 730px) {
    @content;
  }
}
@mixin max-height-710 {
  @media (max-height: 710px) {
    @content;
  }
}
@mixin max-height-700 {
  @media (max-height: 700px) {
    @content;
  }
}
@mixin max-height-690 {
  @media (max-height: 690px) {
    @content;
  }
}
@mixin max-height-650 {
  @media (max-height: 650px) {
    @content;
  }
}

@mixin max-height-649 {
  @media (max-height: 649px) {
    @content;
  }
}
@mixin max-height-600 {
  @media (max-height: 600px) {
    @content;
  }
}


@mixin font($size, $weight, $line-height, $text-color) {
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
  color: $text-color;
}
@mixin flexbox($display, $align-items, $justify-content) {
  display: $display;
  align-items: $align-items;
  justify-content: $justify-content;
}
// custom margin
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-46 {
  margin-bottom: 46px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-30{
  margin-bottom: 30px !important;
}

.mb-40{
  margin-bottom: 40px !important;
}
.mr-16{
  margin-right: 16px !important;
}
.mb-8{
  margin-bottom: 8px !important;
}
.mb-50{
  margin-bottom: 50px !important;
}

