.wizard-ques-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;

  .wizard-ques-input-wrap {
    margin-top: 30px;

    .wizard-ques-input {
      &.rule-input {
        display: flex;
        justify-content: space-between;

        .wizard-input-des {
          &.source-name {
            min-width: 100px;
          }

          &.rule {
            position: relative;
            width: 100%;
            max-width: 100%;
            border-bottom: 1px solid $theme-black;
            display: inline-block;
          }

          .wizard-input-text {
            border: 0px;
            padding: 0px;
            height: 30px;
            border-bottom: 1px;
            background: transparent;
            width: 100%;
            outline: 0;
            font-weight: 500;
            font-size: 14px;
            color: #5a5b5e;
          }
        }

      }

      margin-bottom: 10px;
    }

    .wizard-input-profile-wrap {
      display: flex;
      align-items: center;

      .dropdown-wrapper {
        margin-left: 17px;
        margin-right: 0;
      }
    }

    .btn-primary {
      margin-left: auto;
      margin-top: 16px;
      font-size: 18px;
    }
  }
}

.wizard-ques-head {
  color: $theme-success;
  font-size: 32px;
  font-weight: 700;
  position: relative;

  &::before {
    content: '';
    background: url(../images/double-quotes-logo.svg) no-repeat;
    width: 28px;
    height: 28px;
    position: absolute;
    left: -34px;
    top: 0;
  }
}

.wizard-ques-inner {
  width: 645px;
  padding-right: 40px;

  .my-message {
    width: 482px;
    height: 243px;
    padding: 20px;
    border-radius: 24px 24px 24px 0px;
    background-color: $white;
    margin-top: 30px;

    .ai-img-msg {
      margin-top: 0;
      height: 100%;

      .ai-map-img {
        width: 100%;
        height: 140px;
        object-fit: cover;
      }

      .btn-primary {
        top: initial;
        left: 0;
        right: initial;
        bottom: 0;
        margin: auto;
        width: auto;
        min-width: 161px;
      }
    }
  }
}

.wizard-ques-input {
  width: 409px;
  height: 59px;
  background-color: #DDE2E4;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  padding: 25px;
  border-radius: 23px;
  border-bottom-right-radius: 4px;
  cursor: pointer;

  .checkbox-wrapper {
    &.checkbox-grey-wrap {
      margin-bottom: 0;
      padding: 0;
      background: transparent;
      box-shadow: none;
      width: 100%;
    }
  }
}
