@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?aen9');
  src: url('../fonts/icomoon.eot?aen9#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?aen9') format('truetype'),
    url('../fonts/icomoon.woff?aen9') format('woff'),
    url('../fonts/icomoon.svg?aen9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-windmill:before {
  content: '\e93d';
  color: #fff;
}
.icon-turbine:before {
  content: '\e93f';
  color: #fff;
}
.icon-power:before {
  content: '\e940';
  color: #fff;
}
.icon-plus:before {
  content: '\e941';
  color: #fff;
}
.icon-methane:before {
  content: '\e942';
  color: #fff;
}
.icon-geothermal:before {
  content: '\e943';
  color: #fff;
}
.icon-zoom-out:before {
  content: '\e919';
  color: #5a5b5e;
}
.icon-layer:before {
  content: '\e92c';
  color: #5a5b5e;
}
.icon-export-logo:before {
  content: '\e93b';
  color: #5a5b5e;
}
.icon-notification-icon .path1:before {
  content: '\e900';
  color: rgb(249, 249, 249);
}
.icon-notification-icon .path2:before {
  content: '\e901';
  margin-left: -1em;
  color: rgb(60, 60, 60);
}
.icon-premium-user-icon:before {
  content: '\e902';
  color: #39cfa5;
}
.icon-request-free-icon:before {
  content: '\e903';
  color: #c4bfef;
}
.icon-select-dropdown:before {
  content: '\e904';
}
.icon-toast-error .path1:before {
  content: '\e905';
  color: rgb(255, 255, 255);
}
.icon-toast-error .path2:before {
  content: '\e906';
  margin-left: -1em;
  color: rgb(238, 121, 121);
}
.icon-toast-success .path1:before {
  content: '\e907';
  color: rgb(255, 255, 255);
}
.icon-toast-success .path2:before {
  content: '\e908';
  margin-left: -1em;
  color: rgb(57, 207, 165);
}
.icon-edit-task:before {
  content: '\e909';
}
.icon-note-img:before {
  content: '\e90a';
  color: #ffdc93;
}
.icon-library-img:before {
  content: '\e90b';
  color: #fff;
}
.icon-library-icon:before {
  content: '\e90c';
}
.icon-green-circle-right:before {
  content: '\e90d';
  color: #39cfa5;
}
.icon-red-circle-left:before {
  content: '\e90e';
  color: #ea9476;
}
.icon-purple-circle-right:before {
  content: '\e90f';
  color: #9e95e0;
}
.icon-red-circle-excretion:before {
  content: '\e910';
  color: #ee7979;
}
.icon-black-tick:before {
  content: '\e911';
}
.icon-eye-on:before {
  content: '\e912';
  color: #5a5b5e;
}
.icon-eye-off:before {
  content: '\e913';
  color: #5a5b5e;
}
.icon-white-arrow:before {
  content: '\e914';
  color: #fff;
}
.icon-cube-icon:before {
  content: '\e915';
  color: #fff;
}
.icon-bullet-icon:before {
  content: '\e916';
  color: #fff;
}
.icon-card-next-arrow:before {
  content: '\e917';
  color: #fff;
}
.icon-horizontal-three-dot:before {
  content: '\e918';
  color: #fff;
}
.icon-next-arrow-grey:before {
  content: '\e91a';
  color: #868586;
}
.icon-close-grey-icon:before {
  content: '\e91b';
  color: #5a5b5e;
}
.icon-turbine-small:before {
  content: '\e91c';
  color: #5a5b5e;
}
.icon-solar-panel-small:before {
  content: '\e91d';
  color: #5a5b5e;
}
.icon-battery-small:before {
  content: '\e91e';
  color: #5a5b5e;
}
.icon-electrolysis-small:before {
  content: '\e91f';
  color: #5a5b5e;
}
.icon-water-tank-small:before {
  content: '\e920';
  color: #5a5b5e;
}
.icon-bullet-black:before {
  content: '\e921';
}
.icon-go-simulation-icon:before {
  content: '\e922';
}
.icon-energy-efficiency-img:before {
  content: '\e923';
}
.icon-home-white:before {
  content: '\e924';
  color: #fff;
}
.icon-all-project-icon:before {
  content: '\e925';
  color: #fff;
}
.icon-background-icon:before {
  content: '\e926';
  color: #fff;
}
.icon-dragdrop-icon:before {
  content: '\e927';
  color: #fff;
}
.icon-simulate-icon:before {
  content: '\e928';
  color: #fff;
}
.icon-dropdown-icon:before {
  content: '\e929';
  color: #5a5b5e;
}
.icon-green-tick:before {
  content: '\e92a';
  color: #71b481;
}
.icon-close-icon:before {
  content: '\e92b';
  color: #fff;
}
.icon-success-tick .path1:before {
  content: '\e92d';
  color: rgb(255, 255, 255);
}
.icon-success-tick .path2:before {
  content: '\e92e';
  margin-left: -1em;
  color: rgb(1, 114, 182);
}
.icon-invalid-red:before {
  content: '\e92f';
  color: #e54d4d;
}
.icon-dropdown-trigle:before {
  content: '\e930';
  color: #fff;
}
.icon-model-cube:before {
  content: '\e931';
  color: #fff;
}
.icon-tonnels-logo:before {
  content: '\e932';
  color: #fff;
}
.icon-no-data-logo:before {
  content: '\e933';
  color: #fff;
}
.icon-power-logo:before {
  content: '\e934';
  color: #5a5b5e;
}
.icon-theme-tick:before {
  content: '\e935';
  color: #4387b0;
}
.icon-search-dropdown-icon:before {
  content: '\e936';
  color: #5a5b5e;
}
.icon-search-logo:before {
  content: '\e937';
  color: #5a5b5e;
}
.icon-three-dot-horizontal:before {
  content: '\e938';
  color: #868586;
}
.icon-white-tick:before {
  content: '\e939';
  // color: #f2f3f3;
}
.icon-zoom-in:before {
  content: '\e93a';
  color: #5a5b5e;
}
.icon-undo:before {
  content: '\e93c';
  color: #5a5b5e;
}
.icon-export:before {
  content: '\e93e';
  color: #5a5b5e;
}
