.main-login {
  &.dashboard-main {
    padding-top: 177px;
    justify-content: space-around;

    .container {
      &.dashboard-container {
        @include xx-large-devices {
          margin-left: 81px;
          margin-right: 81px;
        }

        @include computer-max-1440 {
          margin-left: 50px;
          margin-right: 50px;
          max-width: 1200px;
        }
      }

      @include xx-large-devices {
        max-width: 100%;
        margin-left: 81px;
        margin-right: 81px;

        .main-login-wrapper {
          .dashboard-main-wrapper {
            .dashboard-left {
              .data-card-wrap {
                .data-card-body {
                  .list-item {
                    max-width: 350px;
                  }


                }
              }
            }

            .dashboard-right {
              .dashboard-right-inner {
                .activity-sec {
                  max-width: 100%;
                }
              }
            }
          }
        }

        .container {
          max-width: 1320px;
          margin-left: inherit;
          margin-right: inherit;
        }
      }
    }

    &.workbench-dashboard-main {
      .container {
        margin-left: 81px;
        margin-right: 81px;
      }
    }

    .main-login-wrapper {
      display: block;
      min-height: calc(100vh - 177px);
      height: inherit;
      // padding-bottom: 30px;

      .dashboard-main-wrapper {
        .dashboard-left {
          margin-right: 20px;

          @include laptop-max {
            margin-right: 24px;
          }

          @include max-large-devices {
            min-width: auto;
          }

          .dashboard-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 51px;
            padding-right: 16px;

            h1 {
              color: $white;
              text-shadow: 0.496057391166687px 0.599053680896759px 1.0625px rgba(0, 0, 0, 0.07),
                2.182652473449707px 2.635836124420166px 2.200000047683716px rgba(0, 0, 0, 0.11),
                5.357419967651367px 6.469779968261719px 4.387499809265137px rgba(0, 0, 0, 0.14),
                10.317994117736816px 12.46031665802002px 8.600000381469727px rgba(0, 0, 0, 0.17),
                17.362009048461914px 20.96687889099121px 15.8125px rgba(0, 0, 0, 0.21),
                26.787099838256836px 32.348899841308594px 27px rgba(0, 0, 0, 0.28);
              font-size: 45px;
              font-weight: 500;
              line-height: normal;

              @include computer-max-1440 {
                font-size: 40px;
              }
            }

            .dashboard-header-right {
              display: flex;
              align-items: center;
              position: relative;

              &::after {
                content: '';
                filter: blur(75px);
                position: absolute;
                width: 520px;
                height: 70px;
                background-color: rgba(0, 0, 0, 0.8);
              }

              .dashboard-header-models {
                display: flex;
                align-items: center;
                z-index: 2;

                &:first-child {
                  margin-right: 64px;

                  @include computer-max-1440 {
                    margin-right: 40px;
                  }
                }

                .dashboard-header-icon {
                  &.cube-anim-wrapper {
                    background: url(../images/cube-frame-new.svg) no-repeat;
                    width: 53px;
                    height: 53px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                      animation: imageRotating 3s linear infinite;
                    }
                  }

                  img {
                    animation: imageRotating 6s linear infinite;
                  }

                  display: inline-block;
                }

                .dashboard-header-text {
                  display: flex;
                  flex-direction: column;
                  padding-left: 12px;
                  position: relative;

                  .dashboard-header-head {
                    color: $white;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: normal;
                    margin-bottom: 3px;
                  }

                  .dashboard-header-des {
                    color: $white;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: normal;
                  }

                  .my_count {
                    color: #FFF;
                    font-size: 12px;
                    font-weight: 300;
                  }
                }
              }
            }
          }

          .data-card-wrapper {
            margin-bottom: 10px;

            .col-md-4 {
              padding-right: 2px;
              position: relative;
              z-index: 1;

              &:hover {
                z-index: 2;
              }

              &:not(.empty) {
                &:hover {
                  .data-card-main {
                    filter: none;
                    backdrop-filter: none;
                  }
                }
              }

              &:last-child {
                padding-right: 12px;
              }
            }
          }
        }

        .dashboard-right {
          .dashboard-right-inner {
            max-height: 693px;
            overflow-y: auto;
            padding-right: 10px;
            padding-bottom: 30px;
            scrollbar-width: none;
            scrollbar-color: transparent transparent;

            &::-webkit-scrollbar-thumb {
              background-color: transparent;
              border-radius: 10px;
            }

            &::-webkit-scrollbar {
              width: 0px;
            }

            .activity-outer-wrap {
              position: relative;
              margin-bottom: 16px;
              height: 133px;
              transition: height 0.3s ease-in-out;
              overflow: hidden;

              .activity-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 12px;

                .activity-date {
                  color: $white;
                  font-size: 16px;
                  font-weight: 500;
                }

                .mark-read-text {
                  color: $white;
                  text-align: right;
                  font-size: 14px;
                  font-weight: 500;
                  opacity: 0;
                  cursor: pointer;
                }

                &:hover {
                  .mark-read-text {
                    opacity: 1;
                    transition: all 0.3s ease-in-out;
                  }
                }
              }

              .activity-shadow-anim {
                width: calc(100% - 20px);
                max-width: 100%;
                position: absolute;
                bottom: 0px;
                left: 50%;
                height: 20px;
                z-index: 1;
                border-radius: 10px;
                transform: translateX(-50%);
                background-color: rgba(255, 255, 255, 0.4);
                filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.63));
                backdrop-filter: blur(25px);
                cursor: pointer;

                @include xx-large-devices {
                  max-width: 381px;
                }
              }

              &.active {
                height: auto;
                overflow: visible;
                transition: all 3s ease;

                .activity-shadow-anim {
                  display: none;
                }
              }

              &.fade-out {
                opacity: 0;
                max-height: 0px;
                margin: 0px;
                padding: 0px;
                transition: all 1s ease;
              }
            }
          }

          .activity-head-wrap {
            display: flex;

            .activity-head {
              &:first-child {
                margin-right: 40px;
              }

              &.vertical_divider {
                position: relative;

                &::after {
                  content: "";
                  position: absolute;
                  width: 4px;
                  height: 22px;
                  background-color: #fff;
                  right: -21px;
                  top: -4px;
                  border-radius: 6px;
                }
              }
            }
          }

          .activity-head {
            color: rgba(255, 255, 255, 0.5);
            font-size: 14px;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 32px;
            cursor: pointer;

            &.active {
              color: rgba(255, 255, 255, 1);
            }
          }

          .activity-sec {
            background-color: rgb(225 225 226);
            backdrop-filter: blur(25px);
            width: 100%;
            border-radius: 10px;
            padding: 16px;
            max-width: 303px;
            max-height: 150px;
            min-height: 91px;
            margin-bottom: 12px;
            z-index: 2;
            position: relative;
            transition: all 0.5s ease-in-out;
            cursor: pointer;

            &.fade-out {
              opacity: 0;
              max-height: 0px;
              margin: 0px;
              padding: 0px;
              transition: 0.5s ease;
            }

            &:last-child {
              margin-bottom: 0px;
            }

            .dropdown-des {
              color: #444;
              font-size: 14px;
              font-weight: 300;
              line-height: normal;
              margin-bottom: 6px;
              word-break: break-word;
              cursor: pointer;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              white-space: normal;
            }

            .duration-status {
              color: rgba(68, 68, 68, 0.4);
              font-size: 13px;
              font-weight: 400;
              line-height: normal;
            }

            .mark-as-read {
              font-size: 13px;
              font-weight: 700;
              letter-spacing: 0em;
              text-align: left;
              color: #0172B6;
              cursor: pointer;
              position: absolute;
              right: 16px;
              bottom: 14px;
              display: none;
            }

            .dropdown-wrapper {
              .user-icon {
                width: 30px;
                height: 30px;
                min-width: 30px;
                margin-right: 10px;
                font-size: 14px;
                cursor: pointer;

                &.default-user-icon {
                  background-color: #fff;
                  padding: 4px;

                  .user-img {
                    object-fit: contain;
                    object-position: center;
                  }
                }
              }

              .right-sec {
                .tooltip-container {
                  .tooltip-text {
                    width: 255px;
                    left: 78px;
                    top: 17px;
                    max-height: 65px;
                    overflow: auto;

                    @include xx-large-devices {
                      width: 285px;
                      left: 98px;
                    }

                    @include max-large-1280 {
                      left: 74px;
                    }
                  }
                }
              }
            }

            &:hover {
              .mark-as-read {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  &.compare-modal {
    justify-content: flex-start;

    .compare-modal-container {
      margin-left: 81px;

      @include computer-max-1440 {
        max-width: 1200px;
      }
      @include max-large-1280{
        margin-left: 51px;
      }
    }

    .main-login-wrapper {
      .dashboard-main-wrapper {
        .dashboard-left {
          margin-right: 0;
          min-width: auto;
          max-width: 85%;

          @include computer-max-1440 {
            max-width: 100%;
          }

          .dashboard-header {
            display: block;
            margin-bottom: 40px;

            h1 {
              margin-bottom: 10px;
            }

            .dashboard-header-des {
              color: #fff;
              font-size: 16px;
              font-weight: 500;
            }
          }

          .multiselect-wrapper {
            z-index: 3;
            position: relative;
            .row {
              --bs-gutter-x: 8px;
            }
          }
        }

        .chart-right {
          border-top-right-radius: 0;
        }
      }
    }
  }
}

.dashboard-graph {
  color: $white;
  margin-bottom: 35px;

  .dashboard-graph-inner {
    height: 320px;
    background-color: rgba(0, 0, 0, 0.11);
    backdrop-filter: blur(7.359683990478516px);
    width: 100%;
    padding: 27px 30px 20px 30px;
    position: relative;

    .no-data-section {
      width: 100%;

      .no-simulation-title {
        height: 264px;
      }

      &.no-data {
        background-color: transparent;
        backdrop-filter: none;
      }
    }

    .recent-simulation {
      color: $white;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 10px;
      display: block;
    }

    .dashboard-graph-head {
      color: $white;
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 6px;
      cursor: pointer;
    }

    .chart-year {
      color: rgba(255, 255, 255, 06);
      font-size: 12px;
      font-weight: 400;
      display: block;
      margin-bottom: 25px;
    }

    .graph-wrapper {
      height: 82px;

      .highcharts-container {
        min-height: initial !important;
      }
    }

    .back-circle-logo {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }
}

.empty-activities {
  display: block;
  color: $white;
  font-size: 14px;
  font-weight: 300;
  margin-top: 100px;
  text-align: center;
}
