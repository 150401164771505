.ev-graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ev-graph-right {
  display: flex;
  align-items: center;

  .simulation-summary-btn {
    margin-right: 15px;
  }

}

.ev-graph-des {
  color: $white;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
}

.ev-graph-head {
  color: $white;
  text-shadow: 0.496057391166687px 0.599053680896759px 1.0625px rgba(0, 0, 0, 0.07), 2.182652473449707px 2.635836124420166px 2.200000047683716px rgba(0, 0, 0, 0.11), 5.357419967651367px 6.469779968261719px 4.387499809265137px rgba(0, 0, 0, 0.14), 10.317994117736816px 12.46031665802002px 8.600000381469727px rgba(0, 0, 0, 0.17), 17.362009048461914px 20.96687889099121px 15.8125px rgba(0, 0, 0, 0.21), 26.787099838256836px 32.348899841308594px 27px rgba(0, 0, 0, 0.28);
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 0;
}

.simulation-summary-btn {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 0;
  padding: 14px;
}

.ev-scenario-dropdown {
  .dropdown-toggle {
    &.btn {
      min-width: 235px;
      height: 48px;
      background-color: #4387B0;
      color: $white;
      font-size: 16px;
      position: relative;
      text-align: left;
      padding: 0 32px;

      &::after {
        content: "";
        background: url(../images/dropdown-white-icon.svg) no-repeat;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        width: 13px;
        height: 8px;
        border: 0;
      }

      &:hover {
        background-color: #4387B0;
        color: $white;
      }

    }
  }

  .dropdown-menu {
    right: 0 !important;
    left: initial !important;
    top: -2px !important;
    width: 205px;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px);

    .dropdown-item {
      font-size: 15px;

      &:hover {
        font-weight: 600;
      }
    }
  }

  &.summary-dropdown {
    margin-right: 15px;

    .dropdown-toggle {
      width: 48px;
      min-width: 48px;
      height: 48px;
      border-radius: 50%;
      margin: 0;
      padding: 14px;
    }

    .dropdown-menu {
      border-radius: 28px;
      background-color: $primary-color;
      box-shadow: 13.15927px 13.15927px 31.5px 0px rgba(24, 57, 87, 0.15);
      backdrop-filter: blur(14px);
      width: 355px;
      padding: 35px 25px;
      right: -90px !important;
      top: 4px !important;

      .summary-dropdown-title {
        color: $white;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 12px;
      }

      .summary-dropdown-des {
        color: $white;
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;
        margin-bottom: 0;
      }
    }
  }
}

.simulation-summary-popup-wrap {
  position: relative;

  .simulation-summary-popup {
    border-radius: 28px;
    background-color: $primary-color;
    box-shadow: 13.15927px 13.15927px 31.5px 0px rgba(24, 57, 87, 0.15);
    backdrop-filter: blur(14px);
    width: 355px;
    padding: 35px 25px;
    right: -90px !important;
    top: 52px !important;
    position: absolute;
    z-index: 2;

    .close-btn {
      top: 14px;
      right: 20px;
      opacity: .6;

      &:hover {
        background-color: transparent !important;
        opacity: 1;
      }

      .icon-close-grey-icon {
        &::before {
          color: $white;
        }
      }
    }

    .summary-dropdown-title {
      color: $white;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 12px;

      &.parameters-dropdown-title {
        color: $theme-success;
        font-size: 14px;
        font-weight: 700;
        text-decoration-line: underline;
        margin-bottom: 16px;
      }
    }

    .summary-dropdown-des {
      color: $white;
      font-size: 16px;
      font-weight: 400;
      line-height: 160%;
      margin-bottom: 0;

      &.parameters-dropdown-des {
        color: $theme-black;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }

  &.parameters-dropdown-btn {
    position: absolute;
    right: 0;
    top: -2px;
  }

}

.simulation-summary-wrap {
  margin-top: 50px;
  width: 100%;

  .simulation-summary-inner {
    border-radius: 28px;
    background-color: rgba(2, 114, 182, 0.87);
    box-shadow: 13.15927px 13.15927px 31.5px 0px rgba(24, 57, 87, 0.15);
    backdrop-filter: blur(14px);
    padding: 45px 35px;

    .simulation-summary-head {
      color: $white;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 16px;
      line-height: normal;
    }

    .simulation-summary-des {
      color: $white;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.ev-chat-modal {
  .modal-dialog {
    .modal-content {
      padding-bottom: 0;

      .modal-body {
        padding-bottom: 0;
      }
    }
  }

  .create-modal-wrapper {
    width: 100%;
  }
}

.parameters-dropdown-wrap {
  position: relative;

  .parameter-label {
    white-space: nowrap;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .parameters-dropdown-btn {
    .btn-primary {
      height: 24px;
      width: auto;
      min-width: 109px;
      padding: 5px 9px;
      color: $white;
      font-size: 12px;
      font-weight: 700;

      .dropdown-arrow-logo {
        margin-left: 4px;
      }
    }

    .simulation-summary-popup {
      border-radius: 0;
      background-color: $white;
      box-shadow: 0;
      backdrop-filter: none;
      width: 220px;
      padding: 25px 4px 25px 20px;
      right: 0 !important;
      top: 28px !important;

      .parameters-popup-inner {
        max-height: 200px;
        overflow-y: auto;
      }
    }
  }
}

.demand-with-arrows {
  display: flex;
  justify-content: space-between;
}

.highcharts-button-box {
  color: rgba(90, 91, 94, 0.4);
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  font-weight: 600;
  fill: transparent;

  &:hover {
    color: $theme-black;
  }
}

.highcharts-button-normal {
  text {
    color: rgba(90, 91, 94, 0.4);
    fill: rgba(90, 91, 94, 0.4) !important;
    font-weight: 600 !important;
    font-family: 'Urbanist', sans-serif;
  }

  &:hover {
    color: $theme-black;

    .highcharts-button-box {
      color: $theme-black;
    }
  }

  .highcharts-button-box {
    fill: transparent;
  }
}

.highcharts-button-hover {
  .highcharts-button-box {
    fill: transparent;
    color: $theme-black;
  }
}

.highcharts-axis-labels {
  text {
    color: $theme-black !important;
    text-align: right;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 10px !important;
    font-weight: 400;
    line-height: normal;
  }
}

.highcharts-legend-item {
  &.highcharts-column-series {
    text {
      fill: $theme-black !important;
      font-family: 'Urbanist', sans-serif !important;
      font-size: 14px !important;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.highcharts-no-tooltip {
  &:hover {
    text {
      color: $theme-black !important;
      fill: $theme-black !important;
      font-weight: 700 !important;
      font-family: 'Urbanist', sans-serif !important;
    }
  }
}

.highcharts-button-pressed {
  text {
    color: $theme-black !important;
    fill: $theme-black !important;
    font-weight: 700 !important;
    font-family: 'Urbanist', sans-serif !important;
  }
}

.highcharts-contextbutton {
  path {
    fill: rgba(90, 91, 94, 0.4);
    stroke: rgba(90, 91, 94, 0.4);
  }

  &:hover,
  &:active {
    path {
      fill: $theme-black;
      stroke: $theme-black;
    }
  }
}
