.main-login {
  background: url(../images/main-bg.png) no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  &.reset-pw-main {
    background: url(../images/modal-bg.png);
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;
    &::after {
      background: transparent;
    }
    .close-btn {
      .icon-close-grey-icon {
        &::before {
          color: rgba(90, 91, 94, 0.5);
        }
      }
      &:hover {
        background-color: #e4e4e4 !important;
      }
    }
    .reset-pw-wrapper {
      min-height: 100vh;
      height: 100%;
      .form-content-main {
        overflow: visible;
      }
    }
  }
  &.admin-login-main{
    .main-login-wrapper{
      .login-right{
        width: 540px;
        padding: 45px 50px;
        height: auto;
        .forgot-pw{
          text-align: center;
          width: 100%;
          display: block;
        }
        .login-header{
          padding: 0;
        }
      }
    }
  }
  .close-btn {
    position: absolute;
    top: 40px;
    right: 81px;
    background-color: transparent;
    border: 0;
    z-index: 9;

    &:hover {
      background-color: rgba(228, 228, 228, 0.28) !important;
    }

    .icon-close-grey-icon {
      &::before {
        color: $white;
        font-size: 15px;
      }
    }
  }

  &::after {
    content: "";
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    min-height: 100vh;
  }

  .form-group {
    &.file-input {
      .form-control[type="file"] {
        opacity: 0;
        height: 40px;
      }

      .file-input-wrapper {
        display: flex;
        align-items: flex-end;

        .file-upload-img {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          border: 1px dashed #a0a0a0;
          min-width: 150px;
          height: 150px;

          .file-main-img {
            & + .file-main-img {
              z-index: 1;
            }
          }

          .cross-logo {
            position: absolute;
            top: -12px;
            right: -12px;
            z-index: 3;
          }
        }

        .file-input-text-wrapper {
          position: relative;
          margin-left: 35px;

          .file-attachment-wrapper {
            position: absolute;
            bottom: 0;

            .file-input-attachment {
              color: $primary-color;
              text-align: left;
              font-size: 16px;
              font-weight: 600;
              line-height: normal;
              text-decoration-line: underline;
            }

            .file-input-des {
              color: $theme-black;
              font-size: 12px;
              font-weight: 400;
              line-height: normal;
              display: block;
              margin-top: 4px;
              text-decoration-line: none !important;
            }
          }
        }
      }
    }

    &.error-field {
      .file-input-wrapper {
        .file-upload-img {
          border: 1px dashed $text-error;
          position: relative;

          .error-msg {
            display: block;
            top: 188px;
            position: absolute;
            right: 0;
          }
        }
      }

      .error-msg {
        bottom: -20px;
        position: absolute;
        right: 0;
      }
    }
  }

  .main-login-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 2;

    &.verify-email-wrapper {
      .form-content-main {
        &.login-form-content {
          flex-direction: column;
          padding-top: 75px;
          padding-bottom: 50px;

          .dsider-logo {
            margin-bottom: 50px;
          }

          .login-right {
            height: auto;

            &.reset-pw-right {
              height: auto;
              border-radius: 0;
              background-color: #e8eaef;
              filter: drop-shadow(
                  13.159272193908691px 13.159272193908691px 23.49869728088379px
                    rgba(24, 57, 87, 0.15)
                )
                drop-shadow(
                  -10.809399604797363px -10.809399604797363px
                    21.148826599121094px #f6f6f6
                );
            }

            .login-header {
              padding: 0 58px;

              .title-des {
                margin-bottom: 45px;
              }
            }

            .login-form {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;

              .form-group {
                width: 100%;
                &.code-input {
                  input {
                    font-size: 22px;
                    color: #5a5b5e !important;
                  }
                }

                &.mb-45 {
                  margin-bottom: 45px;
                }
              }

              .no-account {
                margin-bottom: 0;
              }

              .form-group-wrapper {
                display: flex;
                margin-bottom: 0;

                .error-msg {
                  text-align: center;
                  top: -31px;
                  left: 0;

                  .icon-invalid-red {
                    margin-right: 5px;
                  }
                }

                .form-group {
                  margin-bottom: 63px;

                  input {
                    width: 85px;
                    height: 85px;
                    text-align: center;

                    &:focus {
                      border-color: $theme-black;
                    }

                    &.no-border-right {
                      border-right: 0;
                    }
                  }
                }
              }

              &.reset-form {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                flex-direction: column;

                .form-group {
                  input {
                    width: 100%;
                    height: 54px;
                    text-align: left;

                    &.no-border-right {
                      border-right: inherit;
                    }
                  }
                }

                .sign-btn-wrapper {
                  margin-top: 0;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .login-left {
      width: 406px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .dsider-logo {
        margin-bottom: 20px;
        width: 117px;
      }

      h2 {
        font-size: 50px;
        font-weight: 700;
        line-height: normal;
        color: $white;
        margin-bottom: 22px;
        @include max-height-650{
          font-size: 40px;
        }
      }

      .login-left-des {
        color: $white;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
      }
    }

    .form-content-main {
      padding-top: 135px;
      padding-bottom: 50px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-width: none;
      scrollbar-color: transparent transparent;

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 10px;
      }
      &::-webkit-scrollbar {
        width: 0px;
      }
      @include max-height-730 {
        padding-top: 80px;
      }

      &.login-form-content {
        display: flex;
        align-items: center;
        padding-top: 0px;
        position: relative;
        padding-bottom: 0px;
      }
    }

    .login-right {
      width: 485px;
      backdrop-filter: blur(22px);
      background-color: rgba(255, 255, 255, 0.85);
      height: 572px;
      border-radius: 64px;
      padding: 45px 0px;

      .forgot-ps-wrapper {
        position: relative;
      }

      &.signup-right {
        height: auto;
        padding: 45px 0px;

        .login-header {
          h3 {
            margin-bottom: 10px;
          }

          .title-des {
            margin-bottom: 45px;
          }
        }

        .signup-form {
          .no-account {
            margin-bottom: 0px;
          }
        }
      }

      .login-header {
        padding: 0px 50px;
        text-align: center;

        h3 {
          color: $theme-black;
          text-align: center;
          font-size: 30px;
          font-weight: 500;
          line-height: normal;
        }

        .title-des {
          color: $theme-black;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 35px;
          display: block;
        }
      }

      .login-form {
        padding: 0px 50px;

        .sign-btn-wrapper {
          display: flex;
          justify-content: center;
          margin-top: 45px;
          margin-bottom: 33px;

          .btn-primary {
            max-width: 180px;
            padding: 20px 32px;
            font-size: 16px;
            font-weight: 600;
            filter: drop-shadow(
                13.159272193908691px 13.159272193908691px 23.49869728088379px
                  rgba(24, 57, 87, 0.15)
              )
              drop-shadow(
                -10.809399604797363px -10.809399604797363px 17.149999618530273px
                  #fff
              );
          }
        }

        .no-account {
          display: block;
          text-align: center;
          margin-bottom: 16px;

          .sign-up {
            color: $primary-color;
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .forgot-pw {
          display: block;
          text-align: center;
          color: $primary-color;
          font-weight: 600;
          text-decoration: underline;
        }

        .password-toggle {
          position: absolute;
          right: 7px;
          bottom: 22px;

          .eye-off {
            position: relative;
            cursor: pointer;

            &::after {
              content: "";
              background: url(../images/eye-off.svg);
              position: absolute;
              right: 15px;
              bottom: -4px;
              width: 18px;
              height: 18px;
            }
          }

          .eye-on {
            position: relative;
            cursor: pointer;

            &::after {
              content: "";
              background: url(../images/eye-on.svg);
              position: absolute;
              right: 15px;
              bottom: -4px;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    .forgot-ps-dropdown {
      top: 50%;
      position: absolute;
      display: block;
      left: 50%;
      transform: translate(-50%, -28%);

      .modal-content {
        width: 350px;
      }

      .modal-head {
        font-size: 16px;
        font-weight: 600;
      }

      .modal-text {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
      }

      .forgot-ps-footer {
        display: flex;
        justify-content: flex-end;
        .primary-medium {
          filter: drop-shadow(
              13.159272193908691px 13.159272193908691px 23.49869728088379px
                rgba(24, 57, 87, 0.15)
            )
            drop-shadow(
              -10.809399604797363px -10.809399604797363px 17.149999618530273px
                #fff
            );
          .spinner-border {
            --bs-spinner-width: 15px;
            --bs-spinner-height: 15px;
          }
        }

        .btn-no-outline {
          padding: 0 24px;
        }
      }
    }
  }
}
.sign-ul{
  margin-bottom: 0;
  margin-left: -43px;
  .sign-li-wrap{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
    .sign-li{
      color: $white;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0px;
      &::before{
        content: "\2022"; /* Unicode character for a small bullet */
        font-size: 0.8em; /* Adjust the size of the marker */
        margin-right: 8px; /* Add some space between the marker and the content */

      }
    }
    .sign-li-des{
      color: $white;
      font-size: 12px;
      font-weight: 500;
      margin-top: 8px;
      margin-left: 12px;
      display: block;
    }

  }

}
.admin-login-card-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.admin-login-card{
  width: 200px;
  height: 200px;
  border-radius: 40px;
  background: #fff;
  padding: 50px 30px 24px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.admin-login-card-des{
  color: $theme-black;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.admin-login-card-img{
  margin-bottom: 20px;
}
