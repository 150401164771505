.navbar-expand-lg {
  background-color: transparent !important;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1024;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &.header-scroll-bg {
    background-color: #f0f0f1 !important;

    &.dashboard-header-scroll {
      .container {
        .navbar-nav {
          .nav-link {
            color: rgba(90, 91, 94, 0.5);

            &:hover,
            &.active {
              color: rgba(90, 91, 94, 1);
            }
          }

          .dropdown {
            &.request-dropdown {
              .dropdown-toggle {
                border-color: $theme-black;
                color: $theme-black;
              }

              &.icon-white-arrow {
                &::before {
                  color: $theme-black;
                }
              }
            }

            &.profile-dropdown {
              .dropdown-toggle {
                &::after {
                  color: $theme-black !important;
                }
              }
            }
          }

          .btn-primary {
            &.appoval-btn {
              color: $theme-black !important;
              border-color: $theme-black !important;
              background-color: transparent;
            }
          }

          &.nav-dropdown-wrapper {
            .close-btn {
              .icon-close-grey-icon {
                &::before {
                  color: $theme-black;
                }
              }
            }
          }
        }
      }
    }
  }

  &.workbench-header-scroll-bg {
    fill: rgba(255, 255, 255, 0.9) !important;
    backdrop-filter: blur(25px);
  }

  .container {
    margin-left: 81px;
    margin-right: 81px;

    @include max-large-1280 {
      margin-left: 50px;
      margin-right: 50px;
    }

    .navbar-brand {
      margin-right: 51px;
      padding: 0;

      img {
        cursor: pointer;
      }
    }

    .navbar-nav {
      &.nav-dropdown-wrapper {
        position: absolute;
        right: 0;
      }

      .nav-link {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        padding: 0;
        margin: 0 23px;

        &:hover,
        &.active {
          color: rgba(255, 255, 255, 1);
        }
      }

      .dropdown {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &.request-dropdown {
          width: 94px;
          margin-right: 47px;
          position: relative;
          font-family: "Urbanist", sans-serif !important;

          &.pending-request {
            &::after {
              content: "";
              width: 10px;
              height: 10px;
              position: absolute;
              top: -3px;
              right: -6px;
              background-color: #fa7575;
              border-radius: 50%;
              border: 1px solid $white;
            }

          }

          &.icon-white-arrow {
            &::before {
              font-size: 10px;
              position: absolute;
              left: 10px;
              font-family: "icomoon" !important;
            }
          }

          .dropdown-toggle {
            color: #fff;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
            border: 1px solid #fff;
            padding: 6px 10px 8px 28px;
            font-family: "Urbanist", sans-serif !important;

            &::after {
              border: none;
              display: none;
            }
          }

          .dropdown-menu {
            width: 401px;
            background-color: rgba(255, 255, 255, 0.85);
            backdrop-filter: blur(25px);
            padding: 30px 0px 10px;
            position: absolute;
            right: -96px;
            top: 51px;
            left: inherit;
            border: 0;
            border-radius: 0;

            &::before {
              content: "";
              background: url(../images/dropdown-trigle.svg) no-repeat;
              position: absolute;
              top: -10px;
              right: 30%;
              width: 20px;
              height: 20px;
            }

            .custom-dropdown {
              h2 {
                font-size: 16px;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 25px;
                padding: 0 23px;
              }

              .dropdown-wrapper-main {
                padding: 0;
                overflow-y: auto;
                max-height: 280px;
                margin-right: 4px;
                min-height: 280px;

                .dropdown-wrapper {
                  padding: 20px;
                  margin-bottom: 0px;
                  position: relative;

                  &:last-child {
                    margin-bottom: 0;
                  }
                  .right-sec {
                    width: 100%;
                  }
                }

                .no-result {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }


        &.profile-dropdown {
          .dropdown-toggle {
            background-color: transparent;
            outline: none;
            box-shadow: none;
            padding: 0;
            width: auto;
            min-width: auto;
            filter: none;

            &:hover {
              border-color: none;
            }

            .user-icon {
              width: 30px;
              height: 30px;
              min-width: 30px;
              border-radius: 50%;
              margin-right: 6px;
              border: 1px solid $white;
              background-color: $theme-orange;
              color: $white;
              font-size: 16px;
              font-weight: 600;
              line-height: normal;
              display: flex;
              justify-content: center;
              align-items: center;
              text-transform: uppercase;

              .user-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
                margin: 0;
                padding: 0;
                object-position: top;
              }
            }
          }

          .dropdown-menu {
            border-radius: 40px;
            background: rgba(255, 255, 255, 0.76);
            backdrop-filter: blur(19.5px);
            width: 350px;
            left: inherit;
            padding: 34px 30px;
            top: calc(100% + 10px);

            .dropdown-wrapper {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              padding-bottom: 20px;
              margin-bottom: 20px;

              .user-icon {
                width: 56px;
                min-width: 56px;
                height: 56px;
                border: 1px solid $white;
                background-color: $theme-orange;
                font-size: 20px;
                text-transform: uppercase;
              }

              .right-sec {
                .dropdown-head {
                  font-weight: 600;
                  color: $theme-black;
                  margin-bottom: 4px;
                }

                .dropdown-email {
                  color: $theme-black;
                  font-size: 15px;
                  font-weight: 400;
                  line-height: normal;
                  margin-bottom: 16px;
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 230px;
                }

                .dropdown-footer {
                  justify-content: flex-start;

                  .btn-outline {
                    &.small {
                      font-weight: 600;
                      border-radius: 20px;
                      border-color: $theme-black;
                    }
                  }
                }

                .tooltip-container {
                  .tooltip-text {
                    top: 70%;
                  }
                }
              }
            }

            .account-setting {
              display: flex;
              flex-direction: column;

              .account-item {
                color: $theme-black;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 15px;

                &.sign-out {
                  color: $theme-orange;
                  margin-bottom: 20px;
                }
              }
            }

            .version-sec {
              text-align: right;

              img {
                margin-right: 5px;
              }

              .version-text {
                color: rgba(90, 91, 94, 0.5);
              }
            }
          }
        }

        &.create-modal-dropdown {
          margin-left: 20px;

          .dropdown-toggle {
            border-radius: 0;
            height: 63px;
            background: rgba(0, 0, 0, 0.29);
            backdrop-filter: blur(8.5px);
            color: $white;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
            min-width: 206px;
            filter: none;

            &::after {
              border: 0;
            }
          }

          .dropdown-menu {
            background-color: rgba(255, 255, 255, 0.7);
            backdrop-filter: blur(10px);
            width: 187px;
            border-radius: 0;
            padding: 20px 15px;
            top: calc(100% + 20px);
            left: 0;

            .dropdown-item-text {
              padding: 0 0px 18px;
              font-size: 12px;
              font-weight: 600;
              line-height: normal;
              color: rgba(90, 91, 94, 0.6);
            }

            .dropdown-item {
              padding: 0 0px 16px;
              font-size: 14px;
              color: $text-grey;

              &:hover {
                background-color: transparent;
                color: $text-grey;
              }

              &:last-child {
                padding: 0 0px 0px;
              }
            }
          }
        }

        &.export-dropdown {
          margin-left: 0;

          .dropdown-toggle {
            padding: 0;
            margin: 0 10px 0 0;
            height: 30px;
            min-width: auto;
            background-color: transparent;
          }

          .dropdown-menu {
            top: 100%;
            left: inherit;
            width: 170px;

            &.export-excel-menu {
              width: 197px;
            }
          }
        }

        .dropdown-toggle {
          color: #fff;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
          margin: 0;
          text-transform: capitalize;

          &::after {
            font-size: 18px;
            border-top: 0.4em solid;
          }
        }
      }
    }
  }
}

// profiler-container css
.dropdown-wrapper {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 25px;
  margin-bottom: 25px;

  &.shared-profile {
    .user-icon {
      min-width: 36px;
      width: 36px;
      height: 36px;
      background-color: $white;
      box-shadow: 13.159272193908691px 13.159272193908691px 23.49869728088379px rgba(24, 57, 87, 0.15),
        -10.809399604797363px -10.809399604797363px 21.148826599121094px #f6f6f6;

      .user-img {
        object-fit: contain;
        width: 100%;
        height: 17px;
        border-radius: 0;
      }

      &.orange {
        background-color: $theme-orange;
      }

      &.blue {
        background-color: #4387B0;
      }

      &.darkblue {
        background-color: #0172b6;
      }

      &.medium {
        width: 40px;
        height: 40px;
        min-width: 40px;
      }

      &.extra-large {
        width: 60px;
        height: 60px;
        min-width: 60px;
        font-size: 16px;
      }
    }

    .right-sec {
      .shared-by {
        color: #0172b6;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 5px;
        display: block;
      }

      .dropdown-head {
        font-size: 16px;
        font-weight: 600;
        color: $theme-black;
        margin-bottom: 5px;

        &.bold {
          font-weight: 700;
        }

        &.extra-large {
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 2px;
        }
      }

      .dropdown-des {
        font-size: 12px;
        font-weight: 500;
        color: $theme-black;
        margin-bottom: 0;

        &.mediam {
          font-size: 14px;
          font-weight: 400;
        }

        &.extra-large {
          font-size: 14px;
        }

        &.email {
          color: rgba(90, 91, 94, 0.7);
        }
      }
    }
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.object-dropdown-wrapper {
    .right-sec {
      .dropdown-head {
        font-weight: 700;
      }

      .dropdown-des {
        font-size: 14px;
        font-weight: 400;
      }

      .object-dropdown-wrap {
        .all-object-dropdown-wrap {
          margin-left: 4px;
          display: inline-flex;

          .dropdown-toggle {
            font-size: 14px;
            font-weight: 400;
            color: $theme-black;
            text-decoration: underline;

            &::after {
              border: 0;
            }
          }

          .dropdown-menu {
            max-height: 140px;
            overflow-y: auto;
            padding: 25px 15px;
            width: 190px;
            left: initial !important;
            right: -70px !important;

            &::-webkit-scrollbar {
              width: 3px;
              height: 3px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $theme-black;
            }

            .dropdown-item-text {
              margin-bottom: 12px;
              padding-bottom: 0;
            }

            .dropdown-item {
              padding-bottom: 0;
              margin-bottom: 15px;
              font-size: 15px;
              font-weight: 500;
              color: $text-grey;

              &:last-child {
                margin-bottom: 0;
              }

              &:hover {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  &.user-name-email {
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .user-icon {
      box-shadow: none;
    }
  }

  .user-icon {
    min-width: 36px;
    width: 36px;
    height: 36px;
    background-color: $theme-orange;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    border: 1px solid $white;

    .user-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: top;
    }
  }

  .right-sec {
    .see-simulation-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0px;
      .tooltip-container{
        width: auto;
        .request-pending {
          background-color: $theme-success;
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
        .tooltip-text{
          width: 112px;
          right: 0;
          left: -40px;
        }
      }

    }
    .dropdown-des-arrow-wrap{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h3 {
      color: $text-grey;
      font-size: 17px;
      font-weight: 700;
      line-height: normal;
    }

    .dropdown-des {
      color: $text-grey;
      font-size: 15px;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 0px;
    }

    .dropdown-footer {
      display: flex;
      justify-content: flex-end;
      padding-left: 10px;

      .btn-no-outline {
        height: 37px;
        color: #fa7575;
        font-weight: 400;
        padding: 0 30px;
      }

      .btn-outline {
        &.small {
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
          min-width: inherit;
          padding: 0;
          height: auto;
          border: none;

          .icon-next-arrow-grey {
            &::before {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.all-models-section {
  .navbar-expand-lg {
    border-bottom: 1px solid #bdbdbd !important;
    background-color: #f2f2f3 !important;

    .navbar-brand {
      .header-dark-logo {
        display: block;
      }
    }

    .navbar-nav {
      .nav-link {
        color: rgba(90, 91, 94, 0.5);

        &:hover,
        &.active {
          color: rgba(90, 91, 94, 1);
        }
      }

      .dropdown {
        &.request-dropdown {
          &::before {
            color: #5a5b5e;
          }
        }

        .dropdown-toggle {
          border-color: #5a5b5e !important;
          color: #5a5b5e !important;

          &::after {
            color: #5a5b5e;
          }
        }

        &.create-modal-dropdown {
          .dropdown-toggle {
            color: #fff !important;
          }
        }
      }
    }
  }
}

// workbench header start header
header {
  .navbar-expand-lg {
    border-bottom: 0;

    .container {
      .navbar-nav {
        .background-link {
          color: #fff;
          text-transform: capitalize;
          display: flex;
          align-items: center;

          &:hover {
            color: #fff;
          }

          .background-logo {
            width: 30px;
            height: 30px;
            margin-right: 11px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        &.nav-dropdown-wrapper {
          right: 81px;
          align-items: center;

          .tooltip-container {
            width: auto;

            .tooltip-text {
              width: max-content;
            }
          }

          .background-logo {
            width: 30px;
            min-width: 30px;
            height: 30px;
            border: 1px solid #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 11px;
          }

          .navbar-nav {
            .nav-link {
              &.background-link {
                display: flex;
                align-items: center;
                margin: 0;
                font-weight: 400;
                color: #fff;
                text-transform: capitalize;
              }
            }
          }

          .setting-btn,
          .btn {
            padding: 0;
            margin: 0 10px 0 0;
            height: 30px;

            &:hover,
            &:active {
              background-color: transparent;
            }

            .user-icon {
              margin-right: 0 !important;

              .user-img {
                margin-left: 0;
              }
            }
          }

          .profile-dropdown {
            margin-right: 10px;

            .dropdown-toggle {
              &::after {
                border: 0;
              }

              .user-icon {
                margin-right: 0 !important;
                background-color: #fff;
              }
            }

            .circle-logo-wrapper {
              .icon-export-logo {
                margin-left: 3px;
              }
            }
          }

          .dropdown.request-dropdown {
            width: 105px;
            height: 33px;
            margin-right: 80px;
            margin-left: 10px;

            &::after {
              display: none;
            }

            .dropdown-toggle {
              padding: 7px 23px 7px 23px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .close-btn {
            padding: 0;
            margin: 0;
            position: static;
            width: 35px;
            height: 35px;
            min-width: 35px;

            &:active {
              background-color: transparent;
              border-color: transparent;
            }

            &:hover {
              background-color: rgb(228, 228, 228, 0.28) !important;
            }

            .icon-close-grey-icon {
              min-width: 18px;
              height: 18px;
              width: 18px;

              &::before {
                color: #fff;
                font-size: 18px;
              }
            }
          }

          .btn {
            &.btn-primary {
              filter: none;
              backdrop-filter: none;

              &.appoval-btn {
                color: #fff;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
                border: 1px solid #fff;
                border-radius: 0;
                padding: 7px 23px 7px 23px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 0px 0 14px;
              }

              &.base-line {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
}

.admin-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .admin-section-title {
    margin-bottom: 0;
  }
}

.admim-profile-wrap {
  .dropdown {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.request-dropdown {
      width: 94px;
      margin-right: 47px;
      position: relative;
      font-family: "Urbanist", sans-serif !important;

      &::after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        top: -4px;
        right: -8px;
        background-color: #fa7575;
        border-radius: 50%;
        border: 1px solid $white;
      }

      &.icon-white-arrow {
        &::before {
          font-size: 10px;
          position: absolute;
          left: 10px;
          font-family: "icomoon" !important;
        }
      }

      .dropdown-toggle {
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        border: 1px solid #fff;
        padding: 6px 10px 8px 28px;
        font-family: "Urbanist", sans-serif !important;

        &::after {
          border: none;
          display: none;
        }
      }

      .dropdown-menu {
        width: 401px;
        background-color: rgba(255, 255, 255, 0.85);
        backdrop-filter: blur(25px);
        padding: 30px 23px 40px;
        position: absolute;
        right: -96px;
        top: 51px;
        left: inherit;
        border: 0;
        border-radius: 0;

        &::before {
          content: "";
          background: url(../images/dropdown-trigle.svg) no-repeat;
          position: absolute;
          top: -10px;
          right: 30%;
          width: 20px;
          height: 20px;
        }

        .custom-dropdown {
          h2 {
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 25px;
          }
        }
      }
    }

    &.profile-dropdown {
      .dropdown-toggle {
        background-color: transparent;
        outline: none;
        box-shadow: none;
        padding: 0;
        width: auto;
        min-width: auto;
        filter: none;
        height: auto;

        &:hover {
          border-color: none;
        }

        .user-icon {
          width: 36px;
          height: 36px;
          min-width: 36px;
          border-radius: 50%;
          margin-right: 6px;
          border: 1px solid $white;
          background-color: $theme-orange;
          color: $white;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;

          .user-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            margin: 0;
            padding: 0;
            object-position: top;
          }
        }
      }

      .dropdown-menu {
        border-radius: 40px;
        background: $white;
        backdrop-filter: none;
        width: 350px;
        left: inherit;
        padding: 34px 30px;
        top: calc(100% + 10px);
        border: 0;

        .dropdown-wrapper {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 20px;
          margin-bottom: 20px;

          .user-icon {
            width: 56px;
            min-width: 56px;
            height: 56px;
            border: 1px solid $white;
            background-color: $theme-orange;
            font-size: 20px;
            text-transform: uppercase;
          }

          .right-sec {
            .dropdown-head {
              font-weight: 600;
              color: $theme-black;
              margin-bottom: 4px;
            }

            .dropdown-email {
              color: $theme-black;
              font-size: 15px;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 16px;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 230px;
            }

            .dropdown-footer {
              justify-content: flex-start;

              .btn-outline {
                &.small {
                  font-weight: 600;
                  border-radius: 20px;
                  border-color: $theme-black;
                }
              }
            }

            .tooltip-container {
              .tooltip-text {
                top: 70%;
              }
            }
          }
        }

        .account-setting {
          display: flex;
          flex-direction: column;

          .account-item {
            color: $theme-black;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 15px;

            &.sign-out {
              color: $theme-orange;
              margin-bottom: 20px;
            }
          }
        }

        .version-sec {
          text-align: right;

          img {
            margin-right: 5px;
          }

          .version-text {
            color: rgba(90, 91, 94, 0.5);
          }
        }
      }
    }

    &.create-modal-dropdown {
      margin-left: 20px;

      .dropdown-toggle {
        border-radius: 0;
        height: 63px;
        background: rgba(0, 0, 0, 0.29);
        backdrop-filter: blur(8.5px);
        color: $white;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        min-width: 206px;
        filter: none;

        &::after {
          border: 0;
        }
      }

      .dropdown-menu {
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        width: 187px;
        border-radius: 0;
        padding: 20px 15px;
        top: calc(100% + 20px);
        left: 0;

        .dropdown-item-text {
          padding: 0 0px 18px;
          font-size: 12px;
          font-weight: 600;
          line-height: normal;
          color: rgba(90, 91, 94, 0.6);
        }

        .dropdown-item {
          padding: 0 0px 16px;
          font-size: 14px;
          color: $text-grey;

          &:hover {
            background-color: transparent;
            color: $text-grey;
          }

          &:last-child {
            padding: 0 0px 0px;
          }
        }
      }
    }

    &.export-dropdown {
      margin-left: 0;

      .dropdown-toggle {
        padding: 0;
        margin: 0 10px 0 0;
        height: 30px;
        min-width: auto;
        background-color: transparent;
      }

      .dropdown-menu {
        top: 100%;
        left: inherit;
        width: 170px;
      }
    }

    .dropdown-toggle {
      color: $theme-black;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      text-transform: capitalize;

      &::after {
        font-size: 18px;
        border-top: 0.4em solid;
      }

      &:hover {
        color: $theme-black;
      }
    }
  }

}
.header-close-icon{
  img{
    width: 18px;
    height: 18px;
    min-width: 18px;
  }
}
