button {
  box-shadow: none;
  outline: none;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.btn {
  font-size: 16px;
  font-family: $font-primary;
  line-height: normal;
  border: 1px solid transparent;
  padding: 10px 32px;
  border-radius: 1000px;
  height: 60px;
  outline: none;
  text-decoration: none;
  white-space: nowrap;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;

  &.btn-primary {
    font-weight: 600;
    color: #fff;
    background-color: $primary-color;
    border: 0;
    border-radius: 30px;

    &:hover,
    &:active {
      color: #fff;
      background-color: $primary-color;
    }

    &.no-radius {
      border-radius: 0 !important;
      height: 40px;
      padding: 13px 21px;
      font-size: 12px;
    }

    &.disabled {
      background-color: $theme-black;
      cursor: not-allowed;
      border: 0;
      box-shadow: none;
      opacity: 0.8;
    }

    &:disabled {
      cursor: no-drop;
      border: 0;
      box-shadow: none;
      opacity: 0.5;
      pointer-events: inherit;
    }

    &.status {
      border-radius: 20px;
      background-color: $white;
      text-transform: capitalize;
      height: 26px;
      padding: 6px 16px;
      min-width: auto;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;

      &.approved {
        background-color: $approved;
      }

      &.pending {
        background-color: $pending;
      }

      &.rejected {
        background-color: $text-error;
      }
      &.success{
        background-color: $theme-success;
      }

    }

  }

  &.primary-medium {
    height: 45px;
    min-width: 144px;
  }

  &.btn-outline {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $theme-black;
    box-shadow: none;
    line-height: normal;

    &:hover {
      background-color: transparent;
    }

    &.small {
      border: 1px solid $text-grey;
      height: 37px;
      min-width: 102px;
      color: $text-grey;
      border-radius: 0;
      font-size: 16px;
      font-weight: 700;

      &:hover {
        color: inherit;
      }
    }

    &.no-radius {
      border-radius: 0;
      border-color: $text-grey;
      background-color: transparent;
      font-size: 14px;
      color: $white;

      &:hover {
        background-color: transparent;
        color: $white;
      }
    }
  }

  &.btn-no-outline {
    border: 0;
    color: $text-error;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    background-color: transparent;
    box-shadow: none;
    min-width: auto;
    width: auto;
    padding: 0;
    margin: 0;
    height: auto;

    &:hover {
      color: $text-error;
      background-color: transparent;
    }

    &.reload-btn {
      color: $theme-success;
    }
  }

  &.btn-secondary {
    background-color: #ffeded;
    color: $text-error;
    border-color: #ffeded;
  }

  &.btn-theme-green {
    background-color: $text-success;
    box-shadow: none;
    font-size: 18px;
    height: auto;
    padding: 18px 24px;
  }

  &.btn-colored {
    font-size: 14px;
    font-weight: 400;
    padding: 7px 20px 8px;
    background: linear-gradient(125deg,
        #6a9eeb 0%,
        rgba(137, 105, 230, 0.67) 100%);
    box-shadow: 0px 2px 8px 0px rgba(153, 151, 236, 0.55);
    border: 0;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: -12px;
      top: -16px;
      background-image: url("../images/request-free-icon.svg");
      background-size: 27px;
      width: 27px;
      height: 27px;
      display: inline-flex;
      margin-left: -4px;
    }
  }

  &.btn-sm {
    height: 36px;
  }

  &.btn-cancel {
    font-size: 14px;
    font-weight: 400;
    line-height: 115%;
    color: $text-error;
    background-color: transparent;
    box-shadow: none;
    height: auto;
    padding-top: 2px;
    padding-bottom: 2px;

    &:hover {
      border-color: transparent;
    }

    &:disabled {
      color: $text-error;
      background-color: transparent;
      box-shadow: none;
    }
  }

  &.btn-lg {
    padding: 16px 26px;
    font-size: 16px;
    height: 56px;
  }

  &.spinner-btn {
    color: transparent;
    position: relative;

    img {
      opacity: 0;
    }

    .spinner-border {
      position: absolute;
    }
  }
}

.close-btn {
  position: absolute;
  top: 40px;
  right: 81px;
  min-width: auto;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 15px 15px 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &:hover {
    background-color: #e4e4e4 !important;
  }

  .icon-close-grey-icon {
    min-width: 16px;
    height: 16px;
    width: 16px;
    opacity: 1;

    &:hover {
      background-color: transparent !important;
    }

    &::before {
      font-size: 15px;
      color: rgba(90, 91, 94, 0.5);
    }
  }
}

.btn-primary {
  &.shadow {
    filter: drop-shadow(13.159272193908691px 13.159272193908691px 23.49869728088379px rgba(24, 57, 87, 0.15)) drop-shadow(-10.809399604797363px -10.809399604797363px 17.149999618530273px #fff);
  }
}

.danger-text {
  color: $text-error !important;
}

.btn-outline {
  &.coming-soon-btn {
    color: #0172B6;
    border: 0;
    box-shadow: none;
    width: 100%;
    height: 350px;

    &:hover {
      color: #0172B6;
    }

    @include max-height-700 {
      min-height: 260px;
      max-height: 260px;
    }
  }
}

.simulate-btn-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  .btn-primary {
    min-width: 166px;
    height: 45px;
    font-size: 13px;

    &:disabled {
      background-color: #4387b0;
    }

    .export-logo {
      margin-right: 6px;
    }
  }

  &.search-btn-wrap {
    .btn-primary {
      width: 100%;
      font-size: 14px;
      background-color: $theme-success;

      .search-small-logo {
        margin-right: 8px;
      }
    }
  }

  &.ev-charging-date-wrap {
    position: absolute;
    bottom: 30px;
    left: 0;
    justify-content: flex-start;
    padding: 0 30px;

    .ev-charging-date {
      color: rgba(0, 0, 0, 0.22);
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.data-table-link {
  color: $primary-color;
  font-size: 12px;
  font-weight: 600;
  text-decoration-line: underline;
  text-align: center;
  display: block;
  margin-top: 20px;
}

.setting-btn-wrap {
  width: 100%;
  position: initial;
  z-index: 5;
  display: flex;
  justify-content: flex-end;

  .btn-no-outline {
    padding: 0 35px 0 0;
    color: #e54d4d;
    min-width: auto !important;
  }
}
.geo-dropdown-btn{
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  &::after{
    border-bottom: 0;
    border-left: 0.25em solid transparent;
    border-right: 0.25em solid transparent;
    border-top: 0.25em solid;
    content: "";
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    position: absolute;
    right: -16px;
  }
}
.btn.tax-btn{
  color: #fff;
  font-size: 12px!important;
  line-height: 24px;
  font-weight: 400;
  &:after{
    top: 12px;
    right: -12px;
  }
  &:hover, &:focus{
    color: #fff;
  }
}
.tax-credit-btn{
  flex-direction: column!important;
  justify-content: flex-start;
  align-items: flex-start!important;
  .tax-btn{
    min-width: 80px!important;
  }
}
